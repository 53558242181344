import { find, isEmpty } from 'lodash'

const getTypeSort = (type, appSettings) => {

  let type_sort = 999

  if ('category_sorting' in appSettings) {
    const found = find(appSettings.category_sorting, { Category: type })
    if (!isEmpty(found)) {
      type_sort = found.SortOrder
    }
  }

  return type_sort

}

export default getTypeSort