import { setAlerts } from  './../../../actions/alerts'

const handler = async (_action, api, dispatch) => {

  dispatch(setAlerts(null))
  const alerts = await api.alerts.getAlerts()
  dispatch(setAlerts(alerts))

}

export { handler }