import {
  setIsAuthenticatedStatus,
  validateAuthenticationStatus
} from  './../../../actions/user'

const handler = async (action, api, dispatch) => {

  const { payload: { token, type } } = action

  try {
    await api.user.exchangeToken({ token, type })
    dispatch(validateAuthenticationStatus)
  } catch (err) {
    await api.user.clearAuth()
    dispatch(setIsAuthenticatedStatus(false))
  }

}

export { handler }