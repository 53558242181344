const initialState = {
  accounts: null,
  planAccounts: null,
  portfolioAccounts: null,
  baseProjectionData: null,
  dividendHistory: null,
  fullTransactionHistory: null,
  groups: [],
  incomeProjection: null,
  isFetchingAccounts: false,
  pendingLinks: [],
  positions: null,
  transactions: null
}

export { initialState }