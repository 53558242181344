import { uniq, isNull } from 'lodash'

const handler = action => {

  return {
    selectedAccounts:
      isNull(action.payload.selectedAccounts) ? null :
      uniq(action.payload.selectedAccounts)
  }

}

export { handler }
