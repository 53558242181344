import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))

  const { payload: { userPositionId, note } } = action
  await api.notes.createPositionNote({ userPositionId, notes: note })

}

export { handler }
