
const handler = async (action, api) => {

  const { accountId, onComplete } = action.payload

  const response = await api.account.deleteWatchlist({ accountId })

  onComplete && onComplete(response)


}

export { handler }