import createScreener from './screener/createScreener'
import deleteScreener from './screener/deleteScreener'
import getScreeners from './screener/getScreeners'
import updateScreener from './screener/updateScreener'

const screener = {
  createScreener,
  deleteScreener,
  getScreeners,
  updateScreener
}

export default screener