import createNote from './notes/createNote'
import createPositionNote from './notes/createPositionNote'
import createStockNote from './notes/createStockNote'
import deleteNote from './notes/deleteNote'
import deletePositionNote from './notes/deletePositionNote'
import deleteStockNote from './notes/deleteStockNote'
import getPositionNotes from './notes/getPositionNotes'
import getStockNotes from './notes/getStockNotes'
import getTickerNotes from './notes/getTickerNotes'
import updateNote from './notes/updateNote'

const note = {
  createNote,
  createPositionNote,
  createStockNote,
  deleteNote,
  deletePositionNote,
  deleteStockNote,
  getPositionNotes,
  getStockNotes,
  getTickerNotes,
  updateNote
}

export default note
