const handler = (action, { selectedCharts }) => {

  const { kpi, chartId, subChartId, subChartTab } = action.payload
  
  return {
    selectedCharts: {
      ...selectedCharts,
      [kpi]: { chartId, subChartId, subChartTab }
    }
  }
  
}

export { handler }