import { loadGroups } from  './../../../actions/account'

const handler = async (action, api, dispatch) => {

  const { name } = action.payload

  await api.account.createGroup({ name })
  dispatch(loadGroups)

}

export { handler }