import { filter } from 'lodash'

const handler = (action, { groups }) => {

  const newGroups = filter(groups, group => group.userAccountGroupId !== action.payload.id)

  return {
    groups: newGroups
  }

}

export { handler }
