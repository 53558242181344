export const createNote = ({ ticker, notes, userAccountIds }) => ({
  namespace: 'notes',
  type: 'createNote',
  payload: { ticker, notes, userAccountIds }
})

export const updateNote = ({ noteId, notes, userAccountIds }) => ({
  namespace: 'notes',
  type: 'updateNote',
  payload: { noteId, notes, userAccountIds }
})

export const createPositionNote = ({ userPositionId, note }) => ({
  namespace: 'notes',
  type: 'createPositionNote',
  payload: { userPositionId, note }
})

export const createStockNote = ({ ticker, note }) => ({
  namespace: 'notes',
  type: 'createStockNote',
  payload: { ticker, note }
})

export const deletePositionNote = id => ({
  namespace: 'notes',
  type: 'deletePositionNote',
  payload: { id }
})

export const deleteStockNote = ticker => ({
  namespace: 'notes',
  type: 'deleteStockNote',
  payload: { ticker }
})

export const deleteNote = note_id => ({
  namespace: 'notes',
  type: 'deleteNote',
  payload: { note_id }
})

export const loadPositionNotes = {
  namespace: 'notes',
  type: 'loadPositionNotes'
}

export const loadStockNotes = {
  namespace: 'notes',
  type: 'loadStockNotes'
}

export const loadTickerNotes = ticker => ({
  namespace: 'notes',
  type: 'loadTickerNotes',
  payload: { ticker }
})

export const setPositionNotes = positionNotes => ({
  namespace: 'notes',
  type: 'setPositionNotes',
  payload: { positionNotes }
})

export const setStockNotes = stockNotes => ({
  namespace: 'notes',
  type: 'setStockNotes',
  payload: { stockNotes }
})

export const setTickerNotes = tickerNotes => ({
  namespace: 'notes',
  type: 'setTickerNotes',
  payload: { tickerNotes }
})

export const loadAllNotes = {
  namespace: 'notes',
  type: 'loadAllNotes'
}

export const setAllNotes = allNotes => ({
  namespace: 'notes',
  type: 'setAllNotes',
  payload: { allNotes }
})
