import moment from 'moment'
import { isEmpty, isNull } from 'lodash'
import { setIsMarketOpen, setSecondsToChange, loadMarketStatus } from './../../../actions/market'

const handler = async (_action, api, dispatch) => {

  const marketStatus = await api.market.getStatus()

  dispatch(setIsMarketOpen(marketStatus.isOpen))

  const compareTime = isNull(marketStatus.minutesToOpen) ? marketStatus.minutesToClose : marketStatus.minutesToOpen
  const endTime = moment().add(compareTime, 'minutes')
  const secondsToChange = endTime.diff(moment(), 'seconds')

  if (!isEmpty(window._INT_MKT_STAT)) {
    clearInterval(window._INT_MKT_STAT)
  }

  const startInterval = () => {
    window._INT_MKT_STAT = setInterval(() => {
      const secondsRemaining = endTime.diff(moment(), 'seconds')
      if (secondsRemaining <= 0) {
        clearInterval(window._INT_MKT_STAT)
        dispatch(setSecondsToChange(null))
        dispatch(loadMarketStatus)
      } else {
        dispatch(setSecondsToChange(secondsRemaining))
      }
    }, 10000)

  }

  if (compareTime <= 60) {
    if (secondsToChange <= 0) {
      dispatch(loadMarketStatus)
    } else {
      dispatch(setSecondsToChange(secondsToChange))
    }
    startInterval()
  } else {
    setTimeout(startInterval, (compareTime - 60) * 60000)
  }

}

export { handler }
