import { setAllNotes } from './../../../actions/notes'

const handler = async (_action, api, dispatch) => {

  const allNotes = await api.notes.getTickerNotes({ ticker: null })

  dispatch(setAllNotes(allNotes))

}

export { handler }
