export default function createReducer(namespace) {

  const { initialState } = require(`./${namespace}/initialState`)

  const reducer = (state = initialState, action) => {

    let newState = { ...state }
  
    try {
      const { handler } = require(`./${namespace}/${action.type}`)
      newState = { ...newState, ...handler(action, newState) }
    } catch (_err) {
      // NOOP
    }
  
    return newState
  
  }

  return reducer

}