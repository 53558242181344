export const loadAlerts = {
  namespace: 'alerts',
  type: 'loadAlerts'
}

export const setAlerts = alerts => ({
  namespace: 'alerts',
  type: 'setAlerts',
  payload: { alerts }
})

export const createAlert = ({ ticker, alertConfig, onComplete }) => ({
  namespace: 'alerts',
  type: 'createAlert',
  payload: { ticker, alertConfig, onComplete }
})

export const deleteAlert = ({ id, onComplete }) => ({
  namespace: 'alerts',
  type: 'deleteAlert',
  payload: { id, onComplete }
})

export const updateAlert = ({ id, alertConfig, onComplete }) => ({
  namespace: 'alerts',
  type: 'updateAlert',
  payload: { id, alertConfig, onComplete }
})

export const updateAlertList = alert => ({
  namespace: 'alerts',
  type: 'updateAlertList',
  payload: { alert }
})
