import getAppSettings from './global/getAppSettings'
import getUserConfig from './global/getUserConfig'
import { getSectors } from './security'

import { isNull, isEmpty, find } from 'lodash'

export const getAddContribute = state => state.manage.addContribute

export const getBaseProjectionData = state => state.account.baseProjectionData

export const getColumnHeaders = state => {

  const keyVal = {}
  const columnHeaders = getAppSettings(state, 'column_headers')

  if (!isNull(columnHeaders)) {
    columnHeaders.forEach(columnHeader => {
      if (columnHeader.display === 1) {
        keyVal[columnHeader.id] = columnHeader.label
      }
    })
  }

  return keyVal

}

export const getGridColumnHeaders = state => {

  const keyVal = {}
  const columnHeaders = getAppSettings(state, 'column_headers')

  if (!isNull(columnHeaders)) {
    columnHeaders.forEach(columnHeader => {
      if (columnHeader.display === 1 && columnHeader.gridSelectable === 1) {
        keyVal[columnHeader.id] = columnHeader.label
      }
    })
  }

  return keyVal

}

export const getColumnHelpers = state => {

  const keyVal = {}
  const columnHeaders = getAppSettings(state, 'column_headers')

  if (!isNull(columnHeaders)) {
    columnHeaders.forEach(columnHeader => {
      if (columnHeader.display === 1) {
        keyVal[columnHeader.id] = columnHeader.helper
      }
    })
  }

  return keyVal

}

export const getMetricNames = state => {

  const keyVal = {}
  const columnHeaders = getAppSettings(state, 'column_headers')

  if (!isNull(columnHeaders)) {
    columnHeaders.forEach(columnHeader => {
      if (columnHeader.display === 1) {
        if (!isNull(columnHeader.metricTypeName)) {
          keyVal[columnHeader.metricTypeName] = columnHeader.label
        }
      }
    })
  }

  return keyVal

}

export const getMetricHelpers = state => {

  const keyVal = {}
  const columnHeaders = getAppSettings(state, 'column_headers')

  if (!isNull(columnHeaders)) {
    columnHeaders.forEach(columnHeader => {
      if (columnHeader.display === 1) {
        if (!isNull(columnHeader.metricTypeName)) {
          keyVal[columnHeader.metricTypeName] = columnHeader.helper
        }
      }
    })
  }

  return keyVal

}

export const getScreenerFields = state => {

  const keyVal = {}
  const columnHeaders = getAppSettings(state, 'column_headers')

  if (!isNull(columnHeaders)) {
    columnHeaders.forEach(columnHeader => {
      if (columnHeader.display === 1 && (columnHeader.screenable === 'BASIC' || columnHeader.screenable === 'ADVANCE')) {
        if (!isNull(columnHeader.id)) {
          keyVal[columnHeader.id] = {
            key: columnHeader.id,
            classification: columnHeader.screenable,
            category: columnHeader.dataCategory,
            config: isNull(columnHeader.screenerConfig) || isEmpty(columnHeader.screenerConfig) ? {} : JSON.parse(columnHeader.screenerConfig),
            dataFormat: isNull(columnHeader.dataFormat) || isEmpty(columnHeader.dataFormat) ? {} : JSON.parse(columnHeader.dataFormat),
            for_equities: columnHeader.for_equities === 1,
            for_mutual_funds: columnHeader.for_mutual_funds === 1,
            for_etfs: columnHeader.for_etfs === 1,
            for_closed_end_funds: columnHeader.for_closed_end_funds === 1
          }
        }
      }
    })
  }

  const screenerFields = []
  for (let key in keyVal) {
    screenerFields.push(keyVal[key])
  }

  return screenerFields

}

export const getDividendsOnly = state => state.manage.dividendsOnly

export const getKpiDisplay = state => state.manage.kpiDisplay

export const getGridFilter = state => {

  const key = state.manage.selectedModule === 'manage' ? 'gridFilter' : 'gridFilterPlan'

  return state.manage[key]

}

export const getUnprocessedData = state => state.manage.processedData

export const getProcessedData = state => state.manage.processedData

export const getIsProcessingData =  state => state.manage.isProcessingData

// export const getProcessedData = state => {

//   // Base data
//   const config = state.user.config
//   const positions = getPositions(state)
//   const currentMetrics = state.security.currentMetrics
//   // const baseProjectionData = state.account.baseProjectionData

//   // Selections
//   const selectedKpi = state.manage.selectedKpi
//   const selectedAccounts = state.manage.selectedAccounts
//   const dividendsOnly = state.manage.dividendsOnly
//   const selectedSectors = state.manage.selectedSectors
//   const selectedPositionType = state.manage.selectedPositionType
//   const displaySoldPositions = state.manage.displaySoldPositions

//   // Account
//   const accounts = state.account.accounts

//   // Configuration
//   const addContribute = state.manage.addContribute
//   // const reinvestPercent = state.manage.reinvestPercent

//   // Notes
//   const positionNotes = state.notes.positionNotes
//   const stockNotes = state.notes.stockNotes

//   const processedData = processData({
//     config,
//     positions,
//     currentMetrics,
//     selectedKpi,
//     selectedAccounts,
//     selectedSectors,
//     selectedPositionType,
//     dividendsOnly,
//     accounts,
//     // baseProjectionData,
//     addContribute,
//     // reinvestPercent,
//     positionNotes,
//     stockNotes,
//     displaySoldPositions
//   })

//   return processedData

// }

export const getDisplaySoldPositions = state => state.manage.displaySoldPositions

export const getReinvestPercent = state => state.manage.reinvestPercent

export const getSelectedAccounts = state => {

  if (isNull(state.manage.selectedAccounts)) {
    if (!isEmpty(state.account.portfolioAccounts)) {
      return state.account.portfolioAccounts.map(({ userAccountId }) => userAccountId)
    }
  } else {
    return state.manage.selectedAccounts
  }

  return []

}

export const getSelectedWatchlists = state => {

  if (isNull(state.manage.selectedWatchlists)) {
    if (!isEmpty(state.account.planAccounts)) {
      return state.account.planAccounts.map(({ userAccountId }) => userAccountId)
    }
  } else {
    return state.manage.selectedWatchlists
  }

  return []

}

export const getSelectedKpi = state => state.manage.selectedKpi

export const getSelectedPositionType = state => {

  const key = state.manage.selectedModule === 'manage' ? 'selectedPositionType' : 'selectedPositionTypePlan'

  return state.manage[key]

}

export const getSelectedSectors = state => {

  const key = state.manage.selectedModule === 'manage' ? 'selectedSectors' : 'selectedSectorsPlan'

  const sectors = getSectors(state)

  if (isNull(state.manage[key])) {
    if (!isEmpty(sectors)) {
      return sectors.map(({ SectorAbbr }) => SectorAbbr)
    }
  } else {
    return state.manage[key]
  }

  return []

}

export const getDefaultTabColumns = state => {

  const keyVal = {}
  const tabColumns = getAppSettings(state, 'tab_columns')

  if (!isNull(tabColumns)) {
    tabColumns.forEach(tabColumn => keyVal[tabColumn.id] = tabColumn.columns.split(',').map(column => column.trim()))
  }

  return keyVal
  
}

export const getTabColumns = state => {

  const keyVal = {}
  const tabColumns = getAppSettings(state, 'tab_columns')

  if (!isNull(tabColumns)) {
    tabColumns.forEach(tabColumn => keyVal[tabColumn.id] = tabColumn.columns.split(',').map(column => column.trim()))
  }

  return {
    ...keyVal,
    ...getUserConfig(state, 'tab_columns')
  }

}

export const getPredictabilityRatings = state => {

  const ratings = getAppSettings(state, 'predictability_ratings')

  return ratings

}

export const getDiversificationRatings = state => {

  const ratings = getAppSettings(state, 'diversification_ratings')

  return ratings

}

export const getChangeRange = state => {

  const range = getAppSettings(state, 'change_range')

  return range

}

export const getGrowthRates = state => {

  const growth_rates = getAppSettings(state, 'growth_rates')

  return growth_rates

}

export const getTabCharts = state => {

  const keyVal = {}
  const tabCharts = getAppSettings(state, 'tab_charts')

  const key = state.manage.selectedModule === 'plan' ? 'charts_plan' : 'charts'

  if (!isNull(tabCharts)) {
    tabCharts.forEach(tabChart => {
      if (!isEmpty(tabChart[key])) {
        keyVal[tabChart.id] = tabChart[key].split(',').map(chart => chart.trim())
      }
    })
  }

  return {
    ...keyVal,
    // ...getUserConfig(state, 'tab_columns')
  }

}

export const getChartConfig = state => {

  const key = state.manage.selectedModule === 'plan' ? 'subcharts_plan' : 'subcharts'
  
  const subcharts = getAppSettings(state, 'subcharts')
  const charts = isNull(getAppSettings(state, 'charts')) ? [] : getAppSettings(state, 'charts').map(chart => ({
    ...chart,
    subcharts: isEmpty(chart[key]) ? [] : chart[key].split(',').map(subchart => find(subcharts, { id: subchart.trim() }))
  }))

  return charts

}

export const getSelectedCharts = state => state.manage.selectedCharts
export const getSelectedModule = state => state.manage.selectedModule