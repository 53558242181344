export const loadMarketStatus = {
  namespace: 'market',
  type: 'loadMarketStatus'
}

export const setIsMarketOpen = isMarketOpen => ({
  namespace: 'market',
  type: 'setIsMarketOpen',
  payload: { isMarketOpen }
})

export const setSecondsToChange = secondsToChange => ({
  namespace: 'market',
  type: 'setSecondsToChange',
  payload: { secondsToChange }
})