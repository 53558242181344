import getNewNotifications from './notification/getNewNotifications'
import getReadNotifications from './notification/getReadNotifications'
import markAllAsRead from './notification/markAllAsRead'
import markAsRead from './notification/markAsRead'

const notification = {
  getNewNotifications,
  getReadNotifications,
  markAllAsRead,
  markAsRead
}

export default notification