import { isNull } from 'lodash'

const getAnnualIncomeTotals = positions => {

  let totalEstYrIncome = 0
  let totalPaidYtd = 0
  let totalRemaining = 0
  let totalFeesYtd = 0

  if (!isNull(positions)) {
    positions.forEach(position => {
      position.accounts.forEach(account => { 
        totalEstYrIncome += isNaN(account.EstIncome) ? 0 : account.EstIncome
        totalPaidYtd += isNaN(account.PaidYTD) ? 0 : account.PaidYTD
        totalRemaining += isNaN(account.Remaining) ? 0 : account.Remaining
        totalFeesYtd += isNaN(account.FeesYTD) ? 0 : account.FeesYTD
      })
    })
  }

  return {
    totalEstYrIncome,
    totalPaidYtd,
    totalRemaining,
    totalFeesYtd
  }

}

export default getAnnualIncomeTotals
