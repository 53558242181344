const handler = async (action, api) => {

  const { channelId, members, onComplete } = action.payload

  try {
    const response = await api.chat.addChannelMembers({ channelId, members })
    onComplete && onComplete(response)
  } catch (error) {
    console.log(error)
  }

}

export { handler }