export default function createPostMiddleWare(namespace) {

  const middleware = ({ api }) => ({ dispatch, getState }) => next => async action => {
    
    next(action)

    try {
      const { handler } = require(`./post/${namespace}/${action.type}`)
      await handler(action, api, dispatch, getState)
    } catch (_err) {
      // NOOP
    }
    
  }

  return middleware

}