import { isEmpty } from 'lodash'

import { loadCurrentPrices } from './../../../actions/security'

const handler = async (action, api, dispatch, getState) => {

  const state = getState()
  const pricingSymbols = action.payload.pricingSymbols

  if (!isEmpty(window['_INT_PRC_'])) {
    clearInterval(window['_INT_PRC_'])
  }

  // eslint-disable-next-line
  const fetchPrice = async () => {
    const isMarketOpen = state.market.isMarketOpen
    if (isMarketOpen) {
      dispatch(loadCurrentPrices(pricingSymbols))
    }
  }

  window['_INT_PRC_'] = setInterval(fetchPrice, 120000)

}

export { handler }