import io from 'socket.io-client'
import { setSocket } from  './../../../actions/user'
import config from '../../../../config'

import { setIsSocketConnected, setPubSubMessage } from './../../../actions/user'

const handler = async (_action, _api, dispatch) => {

  const socket = io(config.SOCKET_SERVER_URL)

  socket.on('connect', () => {
    dispatch(setIsSocketConnected(true))
  })

  socket.on('socket_message', message => {
    dispatch(setPubSubMessage(message))
  })

  dispatch(setSocket(socket))

}

export { handler }
