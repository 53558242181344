import { filter, includes, isEmpty, isNull } from "lodash";

const filterPositions = (
  positions,
  {
    selectedKpi,
    selectedAccounts,
    selectedSectors,
    selectedPositionType,
    highlightedTickers = null,
    displaySoldPositions,
    logThis = false,
  }
) => {
  if (isNull(positions)) {
    return null;
  }

  let filteredPositions = positions;

  if (!isNull(selectedPositionType)) {
    if (selectedPositionType === "INCOME") {
      filteredPositions = filter(
        filteredPositions.map((position) => ({
          ...position,
          accounts: filter(
            position.accounts,
            (account) =>
              ("divstatus" in account && account.divstatus === "Dividend") ||
              ("Type" in account && account.Type.toUpperCase() === "PREFERRED")
          ),
        })),
        (position) => !isEmpty(position.accounts)
      );
    }

    if (selectedPositionType === "NON_INCOME") {
      filteredPositions = filter(
        filteredPositions.map((position) => ({
          ...position,
          accounts: filter(
            position.accounts,
            (account) =>
              "divstatus" in account &&
              account.divstatus !== "Dividend" &&
              "Type" in account &&
              account.Type.toUpperCase() === "PREFERRED"
          ),
        })),
        (position) => !isEmpty(position.accounts)
      );
    }

    if (selectedPositionType === "OPTIONS") {
      filteredPositions = filter(
        filteredPositions.map((position) => ({
          ...position,
          accounts: filter(
            position.accounts,
            (account) => account.Category.toUpperCase() === "OPTIONS"
          ),
        })),
        (position) => !isEmpty(position.accounts)
      );
    }
  }

  // Filter by selected accounts
  if (!isNull(selectedAccounts)) {
    filteredPositions = filter(
      filteredPositions.map((position) => ({
        ...position,
        accounts: filter(
          position.accounts,
          (account) =>
            includes(selectedAccounts, account.AccountId) ||
            includes(selectedAccounts, account.useraccountid)
        ),
      })),
      (position) => !isEmpty(position.accounts)
    );
  }

  // Filter by selected sectors
  if (!isNull(selectedSectors)) {
    filteredPositions = filter(
      filteredPositions.map((position) => ({
        ...position,
        accounts: filter(position.accounts, (account) =>
          includes(selectedSectors, account.Sector)
        ),
      })),
      (position) => !isEmpty(position.accounts)
    );
  }

  if (!isEmpty(selectedKpi)) {
    // const kpisWithDormantPositions = ['annual_income', 'future_income', 'allocations']
    const kpisWithoutOptions = ["annual_income"];

    // if (!includes(kpisWithDormantPositions, selectedKpi)) {
    //   filteredPositions = filter(filteredPositions.map(position => ({
    //     ...position,
    //     accounts: filter(position.accounts, account => (!('RecordType' in account)) || (('RecordType' in account) && account.RecordType === 'UserPositionsExists'))
    //   })), position => !isEmpty(position.accounts))
    // }

    if (includes(kpisWithoutOptions, selectedKpi)) {
      filteredPositions = filter(
        filteredPositions.map((position) => ({
          ...position,
          accounts: filter(
            position.accounts,
            (account) => account.Category.toUpperCase() !== "OPTIONS"
          ),
        })),
        (position) => !isEmpty(position.accounts)
      );
    }
  }

  if (!displaySoldPositions) {
    filteredPositions = filter(
      filteredPositions.map((position) => ({
        ...position,
        accounts: filter(
          position.accounts,
          (account) =>
            !("RecordType" in account) ||
            ("RecordType" in account &&
              account.RecordType === "UserPositionsExists")
        ),
      })),
      (position) => !isEmpty(position.accounts)
    );
  }

  if (!isNull(highlightedTickers)) {
    filteredPositions = filter(filteredPositions, ({ ticker }) =>
      includes(highlightedTickers, ticker)
    );
  }

  return filteredPositions;
};

export default filterPositions;
