export const getPrice = ({ ticker, type, onComplete }) => ({
  namespace: 'security',
  type: 'getPrice',
  payload: { ticker, type, onComplete }
})

export const loadCurrentPrices = (pricingSymbols, onComplete = null) => ({
  namespace: 'security',
  type: 'loadCurrentPrices',
  payload: { pricingSymbols, onComplete }
})

export const loadMarketIndeces = {
  namespace: 'security',
  type: 'loadMarketIndeces'
}

export const loadSecurity = ({ ticker, mode, onComplete  }) => ({
  namespace: 'security',
  type: 'loadSecurity',
  payload: { ticker, mode, onComplete }
})

export const loadSecurities = ({ tickers, onComplete  }) => ({
  namespace: 'security',
  type: 'loadSecurities',
  payload: { tickers, onComplete }
})

export const loadSecurityDividendHistory = ({ ticker  }) => ({
  namespace: 'security',
  type: 'loadSecurityDividendHistory',
  payload: { ticker }
})

export const loadDividendDetails = ({ ticker, onComplete  }) => ({
  namespace: 'security',
  type: 'loadDividendDetails',
  payload: { ticker, onComplete }
})

export const loadDividendReliability = ({ ticker  }) => ({
  namespace: 'security',
  type: 'loadDividendReliability',
  payload: { ticker }
})

export const loadFinancials = ({ ticker, onComplete  }) => ({
  namespace: 'security',
  type: 'loadFinancials',
  payload: { ticker, onComplete }
})

export const loadFinancialsSummary = ({ ticker, onComplete  }) => ({
  namespace: 'security',
  type: 'loadFinancialsSummary',
  payload: { ticker, onComplete }
})

export const subscribeToRealTimeQuotes = ({ socketId, pricingSymbols }) => ({
  namespace: 'security',
  type: 'subscribeToRealTimeQuotes',
  payload: { socketId, pricingSymbols }
})

export const setCurrentPrices = currentPrices => ({
  namespace: 'security',
  type: 'setCurrentPrices',
  payload: { currentPrices }
})

export const setMarketIndeces = marketIndeces => ({
  namespace: 'security',
  type: 'setMarketIndeces',
  payload: { marketIndeces }
})

export const setQuote = quote => ({
  namespace: 'security',
  type: 'setQuote',
  payload: { quote }
})

export const setSecurity = security => ({
  namespace: 'security',
  type: 'setSecurity',
  payload: { security }
})

export const setSecurityDividendHistory = dividendHistory => ({
  namespace: 'security',
  type: 'setSecurityDividendHistory',
  payload: { dividendHistory }
})

export const setSecurityDividendStreak = dividendStreak => ({
  namespace: 'security',
  type: 'setSecurityDividendStreak',
  payload: { dividendStreak }
})

export const setDividendDetails = dividendDetails => ({
  namespace: 'security',
  type: 'setDividendDetails',
  payload: { dividendDetails }
})

export const searchSecurities = query => ({
  namespace: 'security',
  type: 'searchSecurities',
  payload: { query }
})

export const screenSecurities = ({ filters, cursor, sortBy = 'ticker', sortDirection = 'ASC' }) => ({
  namespace: 'security',
  type: 'screenSecurities',
  payload: { filters, cursor, sortBy, sortDirection }
})

export const setAutoComplete = autoComplete => ({
  namespace: 'security',
  type: 'setAutoComplete',
  payload: { autoComplete }
})

export const setFinancials = financials => ({
  namespace: 'security',
  type: 'setFinancials',
  payload: { financials }
})

export const setFinancialsSummary = financialsSummary => ({
  namespace: 'security',
  type: 'setFinancialsSummary',
  payload: { financialsSummary }
})

export const setDividendReliability = dividendReliability => ({
  namespace: 'security',
  type: 'setDividendReliability',
  payload: { dividendReliability }
})