import { sortBy } from 'lodash'

const handler = (action, { accounts }) => {

  const accountList =  accounts.map(account => {

    if (account.providerAccountId === action.payload.institutionId) {
      return {
        ...account,
        providerAccountAlias: action.payload.institutionAlias,
        institutionAlias: action.payload.institutionAlias
      } 
    }

    return account

  })

  return {
    accounts: sortBy(accountList, 'displayName')
  }

}

export { handler }
