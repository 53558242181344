import { setScreeners } from  './../../../actions/screener'

const handler = async (_action, api, dispatch) => {

  dispatch(setScreeners(null))
  const screeners = await api.screener.getScreeners()
  dispatch(setScreeners(screeners))

}

export { handler }