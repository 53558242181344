import { find } from 'lodash'

const handler = (action, { tickerNotes }) => {

  const newTickerNotes = [...tickerNotes ]

  const currentNote = find(newTickerNotes, { UserNoteId: action.payload.noteId })
  currentNote.notes = action.payload.notes
  currentNote.UserAccountIDs = action.payload.userAccountIds

  return {
    tickerNotes: newTickerNotes
  }

}

export { handler }