import getCurrentPrices from './security/getCurrentPrices'
import getFinancials from './security/getFinancials'
import getFinancialsSummary from './security/getFinancialsSummary'
import getMarketIndeces from './security/getMarketIndeces'
import getPrice from './security/getPrice'
import getSecurity from './security/getSecurity'
import getSecurities from './security/getSecurities'
import getDividendHistory from './security/getDividendHistory'
import getDividendDetails from './security/getDividendDetails'
import getDividendReliability from './security/getDividendReliability'
import screenSecurities from './security/screenSecurities'
import searchSecurities from './security/searchSecurities'

const security = {
  getCurrentPrices,
  getFinancials,
  getFinancialsSummary,
  getMarketIndeces,
  getPrice,
  getSecurity,
  getSecurities,
  getDividendHistory,
  getDividendDetails,
  getDividendReliability,
  screenSecurities,
  searchSecurities
}

export default security
