import { setScreenerResults } from './../../../actions/screener'

const handler = async (action, api, dispatch) => {

  const { filters, cursor, sortBy, sortDirection } = action.payload

  dispatch(setScreenerResults(null))
  const screenerResults = await api.security.screenSecurities({ filters, cursor, sortBy, sortDirection })
  dispatch(setScreenerResults(screenerResults))
  
}

export { handler }