import { setStockNotes } from './../../../actions/notes'

const handler = async (_action, api, dispatch) => {

  const stockNotes = await api.notes.getStockNotes()

  dispatch(setStockNotes(stockNotes))

}

export { handler }
