import { find, isEmpty } from 'lodash'

const handler = (action, { currentMetrics }) => {

  let currentQuotes = [ ...currentMetrics ]
  
  for (let quote of action.payload.quote) {

    const found = find(currentQuotes, { ticker: quote.ticker })

    if (!isEmpty(found)) {
      currentQuotes = currentQuotes.map(currentQuote => {
        if (currentQuote.ticker === quote.ticker) {
          return { ...currentQuote,...quote }
        }
        return currentQuote
      })
    } else {
      currentQuotes.push(quote)
    }

  }

  return {
    currentMetrics: currentQuotes
  }

}

export { handler }
