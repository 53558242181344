const initialState = {
  autoComplete: null,
  currentMetrics: null,
  marketIndeces: [],
  security: null,
  dividendHistory: null,
  financials: null,
  financialsSummary: null,
  dividendStreak: null,
  dividendDetails: null,
  dividendReliability: null
}

export { initialState }