import { setNews } from  './../../../actions/news'

const handler = async (action, api, dispatch) => {

  dispatch(setNews(null))
  const news = await api.news.getNews({
    tickers: action.payload.tickers,
    source: action.payload.source
  })
  dispatch(setNews(news))

}

export { handler }
