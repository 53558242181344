import axios from "axios";
import config from "./../../../config";
import { isEmpty, isNull } from "lodash";

const cancelTokens = {
  screenSecurities: null,
};

const invoke = async (method, data = {}) => {
  if (method in cancelTokens) {
    if (!isNull(cancelTokens[method])) {
      cancelTokens[method]();
      cancelTokens[method] = null;
    }
  }

  const token = localStorage.getItem("@access_token");
  const response = await axios.post(`${config.RPC_API_URL}/${method}`, data, {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
    cancelToken:
      method in cancelTokens
        ? new axios.CancelToken((executor) => (cancelTokens[method] = executor))
        : null,
  });

  if (method in cancelTokens) {
    cancelTokens[method] = null;
  }

  if (!isEmpty(response.data.error)) {
    if (response.data.error.code === "INVALID_TOKEN") {
      localStorage.removeItem("@access_token");
      throw new Error("INVALID_TOKEN");
    }
  }

  return response.data.data;
};

export { invoke };
