import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))

  const response = await api.user.setUserConfig({
    key: 'selectedWatchlists',
    value: action.payload.selectedWatchlists
  })

  action.payload.onComplete && action.payload.onComplete(response)
  

}

export { handler }
