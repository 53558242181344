const handler = async (action, api) => {
  const {
    name,
    description,
    group,
    filters,
    isGlobal,
    isFavorite,
    onComplete,
  } = action.payload;

  const response = await api.screener.createScreener({
    name,
    description,
    group,
    filters,
    isGlobal,
    isFavorite,
  });

  onComplete && onComplete(response);
};

export { handler };
