import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))
  const { ticker } = action.payload

  await api.notes.deleteStockNote({ ticker })
  
}

export { handler }