import { find, head, isNull,  } from 'lodash'

import resolvePrice from './resolvePrice'
import moment from 'moment'

const resolveDayChange = (position, currentMetrics, account = null) => {

   
  let dayChange = 0

  if (isNull(account)) {

    const cachedRecord = find(currentMetrics, { ticker: head(position.accounts).PricingSymbol })

    if (cachedRecord) {
      
      const price = resolvePrice(position, currentMetrics)
      
      let totalShares = 0

      position.accounts.forEach(account => {
        if (!(account.IsInWatchlist && moment().startOf('day').isSame(moment(account.UPCreatedAt).startOf('day')) )) {
          totalShares += account.Quantity
        }
      })

      if (head(position.accounts).Category.toUpperCase() === 'OPTIONS') {
        totalShares = Math.abs(totalShares * 100)
      }

      dayChange = (price - (cachedRecord.previousClose || cachedRecord.price)) * totalShares

    }

  } else {

    if (account.IsInWatchlist && moment().startOf('day').isSame(moment(account.UPCreatedAt).startOf('day') )) {
      
      dayChange = 0

    } else {
      const cachedRecord = find(currentMetrics, { ticker: account.PricingSymbol })

      if (cachedRecord) {

        const price = resolvePrice(position, currentMetrics)
        let totalShares = account.Quantity

        if (account.Category.toUpperCase() === 'OPTIONS') {
          totalShares = Math.abs(totalShares * 100)
        }

        dayChange = (price - (cachedRecord.previousClose || cachedRecord.price)) * totalShares

      }
      
    }

  }

  return dayChange

}

export default resolveDayChange