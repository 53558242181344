import { uniq, isNull } from 'lodash'

const handler = (action, state) => {

  const selectedModule = state.selectedModule

  if (selectedModule === 'manage') {
    return {
      selectedSectors:
        isNull(action.payload.selectedSectors) ? null :
        uniq(action.payload.selectedSectors)
    }
  } else {
    return {
      selectedSectorsPlan:
        isNull(action.payload.selectedSectors) ? null :
        uniq(action.payload.selectedSectors)
    }
  }

}

export { handler }