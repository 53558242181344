import { setPositionNotes } from './../../../actions/notes'

const handler = async (_action, api, dispatch) => {

  const positionNotes = await api.notes.getPositionNotes()

  dispatch(setPositionNotes(positionNotes))

}

export { handler }
