import { filter } from 'lodash'

const handler = (action, { alerts }) => {

  const newAlerts = filter(alerts, alert => alert.userPriceAlertId !== action.payload.id)

  return {
    alerts: newAlerts
  }
  
}

export { handler }