export const setScreenerFilters = (screenerFilters) => ({
  namespace: "screener",
  type: "setScreenerFilters",
  payload: { screenerFilters },
});

export const setFilterOperation = (key, operation) => ({
  namespace: "screener",
  type: "setFilterOperation",
  payload: { key, operation },
});

export const loadScreeners = {
  namespace: "screener",
  type: "loadScreeners",
};

export const setScreeners = (screeners) => ({
  namespace: "screener",
  type: "setScreeners",
  payload: { screeners },
});

export const createScreener = ({
  name,
  description,
  group,
  filters,
  isGlobal,
  isFavorite,
  onComplete,
}) => ({
  namespace: "screener",
  type: "createScreener",
  payload: {
    name,
    description,
    group,
    filters,
    isGlobal,
    isFavorite,
    onComplete,
  },
});

export const deleteScreener = ({ id, onComplete }) => ({
  namespace: "screener",
  type: "deleteScreener",
  payload: { id, onComplete },
});

export const updateScreener = ({
  id,
  name,
  description,
  group,
  isGlobal,
  isFavorite,
  filters,
  onComplete,
}) => ({
  namespace: "screener",
  type: "updateScreener",
  payload: {
    id,
    name,
    description,
    group,
    filters,
    isGlobal,
    isFavorite,
    onComplete,
  },
});

export const setScreenerResults = (screenerResults) => ({
  namespace: "screener",
  type: "setScreenerResults",
  payload: { screenerResults },
});
