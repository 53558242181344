import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))

  const { payload: { noteId, notes, userAccountIds } } = action
  await api.notes.updateNote({ noteId, notes, userAccountIds })

}

export { handler }
