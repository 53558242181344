import { invoke } from './../lib/client'

const getIncomeProjection = async ({
  reinvestPercent = 100,
  additionalContribution = 100
}) => await invoke('getSectionDetails', {
  module: 'manage',
  section: 'income_projection',
  reinvestPercent,
  additionalContribution
})

export default getIncomeProjection
