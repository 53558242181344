import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))
  const { id } = action.payload

  await api.notes.deletePositionNote({ id })
  
}

export { handler }