import { filter } from 'lodash'

const handler =(action, { tickerNotes, allNotes }) => {

  const { note_id } = action.payload

  const newTickerNotes = filter(tickerNotes, ({ UserNoteId }) => UserNoteId !== note_id)
  const newAllNotes = filter(allNotes, ({ UserNoteId }) => UserNoteId !== note_id)

  return {
    tickerNotes: newTickerNotes,
    allNotes: newAllNotes
  }

}

export { handler }
