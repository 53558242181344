
const handler = async (action, api, dispatch) => {

  const { accountId, watchlistName, ticker, qty, note, onComplete, onError } = action.payload

  try {
    const response = await api.account.createWatchlistPosition({ accountId, watchlistName, ticker, qty, note })
    onComplete && onComplete(response)
  } catch (error) {
    onError(error.response.data.error)
  }

}

export { handler }