import { isEmpty, head, find } from "lodash";
import { setAutoComplete } from "./../../../actions/security";

const handler = async (action, api, dispatch) => {
  const { query } = action.payload;

  dispatch(setAutoComplete(null));
  const securities = await api.security.searchSecurities({ query });

  const autocomplete = {
    symbols: [],
    companies: [],
  };

  if (!isEmpty(securities.exactSymbol)) {
    autocomplete.symbols.push(head(securities.exactSymbol));
  }

  for (let result of securities.symbols) {
    const found = find(autocomplete.symbols, { ticker: result.ticker });
    if (!found) {
      autocomplete.symbols.push(result);
    }
  }

  if (!isEmpty(securities.exactCompany)) {
    autocomplete.companies.push(head(securities.exactCompany));
  }

  for (let result of securities.companies) {
    const found = find(autocomplete.companies, { ticker: result.ticker });
    if (!found) {
      autocomplete.companies.push(result);
    }
  }

  dispatch(setAutoComplete(autocomplete));
};

export { handler };
