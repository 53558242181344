import { invoke } from "./../lib/client";

const updateScreener = async ({
  id,
  name,
  description,
  group,
  filters,
  isGlobal,
  isFavorite,
}) =>
  await invoke("updateScreener", {
    id,
    name,
    description,
    group,
    filters,
    isGlobal,
    isFavorite,
  });

export default updateScreener;
