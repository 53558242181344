import { setFullTransactionHistory } from  './../../../actions/account'

const handler = async (action, api, dispatch) => {

  const { userAccountId, page } = action.payload    

  const fullTransactionHistory = await api.account.getFullTransactionHistory({ userAccountId, page })
  dispatch(setFullTransactionHistory(fullTransactionHistory))

}

export { handler }