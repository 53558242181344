const handler = (action, { kpiDisplay }) => {

  const newKpiDisplay = [...kpiDisplay]
  const prevKpi = newKpiDisplay.shift()
  newKpiDisplay.push(prevKpi)
  const selectedKpiIndex = newKpiDisplay.indexOf(action.payload.selectedKpi)
  newKpiDisplay.splice(selectedKpiIndex, 1)
  newKpiDisplay.unshift(action.payload.selectedKpi)
  
  return {
    selectedKpi: action.payload.selectedKpi,
    kpiDisplay: newKpiDisplay
  }
  
}

export { handler }