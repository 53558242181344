import { find } from 'lodash'

const handler =(action, { screenerFilters }) => {

  const { key, operation } = action.payload

  const newScreenerFilters = [ ...screenerFilters ]

  const filter = find(newScreenerFilters, { key })
  filter.op = operation

  return {
    screenerFilters: newScreenerFilters
  }

}

export { handler }
