import { invoke } from './../lib/client'

const exchangeToken = async ({ token, type }) => {

  const response = await invoke('exchangeToken', { token, type })
  localStorage.setItem('@access_token', response.access_token)
  localStorage.setItem('@fresh_logout', 'FALSE')

}

export default exchangeToken