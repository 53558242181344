export const setAddContribute = addContribute => ({
  namespace: 'manage',
  type: 'setAddContribute',
  payload: { addContribute }
})

export const setDividendsOnly = dividendsOnly => ({
  namespace: 'manage',
  type: 'setDividendsOnly',
  payload: { dividendsOnly }
})

export const setGridFilter = gridFilter => ({
  namespace: 'manage',
  type: 'setGridFilter',
  payload: { gridFilter }
})

export const setReinvestPercent = reinvestPercent => ({
  namespace: 'manage',
  type: 'setReinvestPercent',
  payload: { reinvestPercent }
})

export const setSelectedAccounts = selectedAccounts => ({
  namespace: 'manage',
  type: 'setSelectedAccounts',
  payload: { selectedAccounts }
})

export const setSelectedWatchlists = ({selectedWatchlists, onComplete}) => ({
  namespace: 'manage',
  type: 'setSelectedWatchlists',
  payload: { selectedWatchlists, onComplete }
})

export const setSelectedChart = (kpi, chartId, subChartId, subChartTab) => ({
  namespace: 'manage',
  type: 'setSelectedChart',
  payload: { kpi, chartId, subChartId, subChartTab }
})

export const setSelectedKpi = selectedKpi => ({
  namespace: 'manage',
  type: 'setSelectedKpi',
  payload: { selectedKpi }
})

export const setSelectedPositionType = selectedPositionType => ({
  namespace: 'manage',
  type: 'setSelectedPositionType',
  payload: { selectedPositionType }
})

export const setSelectedSectors = selectedSectors => ({
  namespace: 'manage',
  type: 'setSelectedSectors',
  payload: { selectedSectors }
})

export const setDisplaySoldPositions = displaySoldPositions => ({
  namespace: 'manage',
  type: 'setDisplaySoldPositions',
  payload: { displaySoldPositions }
})

export const processData = state => ({
  namespace: 'manage',
  type: 'processData',
  payload: { state }
})

export const setIsProcessingData = isProcessingData => ({
  namespace: 'manage',
  type: 'setIsProcessingData',
  payload: { isProcessingData }
})

export const setSelectedModule = selectedModule => ({
  namespace: 'manage',
  type: 'setSelectedModule',
  payload: { selectedModule }
})