import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))

  const { userPositionId, onComplete } = action.payload

  const response = await api.account.deleteWatchlistPosition({ userPositionId })
 
  // onComplete && onComplete(response)

}

export { handler }