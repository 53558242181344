const initialState = {
  addContribute: 100,
  displaySoldPositions: true,
  dividendsOnly: false, // TODO: Take from string params
  gridFilter: null,
  gridFilterPlan: null,
  isProcessingData: false,
  reinvestPercent: 100,
  processedData: null,
  selectedKpi: 'performance', // TODO: Take from URL
  selectedAccounts: null,
  selectedWatchlists: null,
  selectedModule: 'manage',
  selectedPositionType: null,
  selectedPositionTypePlan: null,
  selectedSectors: null,
  selectedSectorsPlan: null,
  selectedCharts: {
    performance: { chartId: null, subChartId: null, subChartTab: null },
    annual_income: { chartId: null, subChartId: null, subChartTab: null },
    future_income: { chartId: null, subChartId: null, subChartTab: null },
    yield: { chartId: null, subChartId: null, subChartTab: null },
    reliability: { chartId: null, subChartId: null, subChartTab: null },
    allocations: { chartId: null, subChartId: null, subChartTab: null }
  },
  kpiDisplay: [
    'performance',
    'annual_income',
    'future_income',
    'yield',
    'reliability',
    'allocations'
  ]
}

export { initialState }