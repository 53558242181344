import { setFinancialsSummary } from './../../../actions/security'

const handler = async (action, api, dispatch) => {

  const { ticker, onComplete } = action.payload

  if (!onComplete) {
    dispatch(setFinancialsSummary(null))
  }
  
  const financialsSummary = await api.security.getFinancialsSummary({ ticker })

  if (onComplete) {
    onComplete(financialsSummary)
  } else {
    dispatch(setFinancialsSummary(financialsSummary))
  }

}

export { handler }