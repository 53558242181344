const handler =(action, { deletedPositions }) => {

  const { userPositionId } = action.payload

  return {
    deletedPositions: [ ...deletedPositions, ...userPositionId ]
  }

}

export { handler }
