import { find, isEmpty } from 'lodash'

const resolveStockNote = (position, stockNotes) => {

  let note = null

  const ticker = position.ticker
  const found = find(stockNotes, { ticker })
  if (!isEmpty(found)) {
    note = found
  }

  return note

}

export default resolveStockNote