import {
  setAccounts,
  setPortfolioAccounts,
  setPlanAccounts,
} from "./../../../actions/account";
import { filter } from "lodash";

const handler = async (action, api, dispatch) => {
  dispatch(setAccounts(null));
  dispatch(setPortfolioAccounts(null));
  dispatch(setPlanAccounts(null));

  const accounts = await api.account.getAccounts();
  const portfolioAccounts = filter(
    accounts,
    ({ type }) => type.toLowerCase() !== "plan"
  );
  const planAccounts = filter(
    accounts,
    ({ type }) => type.toLowerCase() === "plan"
  );

  dispatch(setAccounts(accounts));
  dispatch(setPortfolioAccounts(portfolioAccounts));
  dispatch(setPlanAccounts(planAccounts));

  action.onComplete && action.onComplete();
};

export { handler };
