import createAlert from './alerts/createAlert'
import deleteAlert from './alerts/deleteAlert'
import getAlerts from './alerts/getAlerts'
import updateAlert from './alerts/updateAlert'

const screener = {
  createAlert,
  deleteAlert,
  getAlerts,
  updateAlert
}

export default screener