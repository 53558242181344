import { setCurrentPrices } from  './../../../actions/security'

const handler = async (action, api, dispatch) => {

  const { pricingSymbols, onComplete } = action.payload

  const currentPrices = await api.security.getCurrentPrices(pricingSymbols)

  if (onComplete) {
    onComplete(currentPrices)
  } else {
    dispatch(setCurrentPrices(currentPrices))
  }

}

export { handler }