import {
  setSecurityDividendHistory,
  setSecurityDividendStreak
} from './../../../actions/security'

const handler = async (action, api, dispatch) => {

  const { ticker } = action.payload

  dispatch(setSecurityDividendHistory(null))
  const dividendHistory = await api.security.getDividendHistory({ ticker })

  if ('history' in dividendHistory) {
    dispatch(setSecurityDividendHistory(dividendHistory.history))
  }

  if ('streak' in dividendHistory) {
    dispatch(setSecurityDividendStreak(dividendHistory.streak))
  }
  
}

export { handler }