import account from './account'
import alerts from './alerts'
import billing from './billing'
import security from './security'
import market from './market'
import news from './news'
import notes from './notes'
import notification from './notification'
import screener from './screener'
import user from './user'
import yodlee from './yodlee'
import chat from './chat'

const api = {
  account,
  alerts,
  billing,
  security,
  market,
  news,
  notes,
  notification,
  screener,
  user,
  yodlee,
  chat
}

export default api