import { isEmpty } from "lodash";

const handler = async (_action, api) => {
  if (!isEmpty(window._INT_USER_STAMP)) {
    clearInterval(window._INT_USER_STAMP);
  }

  const startInterval = () => {
    window._INT_USER_STAMP = setInterval(async () => {
      await api.user.stampUser();
    }, 10000);
  };

  startInterval();
};

export { handler };
