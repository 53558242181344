import createGroup from './account/createGroup'
import createWatchlist from './account/createWatchlist'
import createWatchlistPosition from './account/createWatchlistPosition'
import deleteAccount from './account/deleteAccount'
import deleteGroup from './account/deleteGroup'
import deleteWatchlist from './account/deleteWatchlist'
import deleteWatchlistPosition from './account/deleteWatchlistPosition'
import fetchPortfolio from './account/fetchPortfolio'
import getAccounts from './account/getAccounts'
import getBaseProjectionData from './account/getBaseProjectionData'
import getFullTransactionHistory from './account/getFullTransactionHistory'
import getGroups from './account/getGroups'
import getView from './account/getView'
import getMonthlyDividend from './account/getMonthlyDividend'
import getIncomeProjection from './account/getIncomeProjection'
import getTransactions from './account/getTransactions'
import setProviderAccountAlias from './account/setProviderAcountAlias'
import updateAccount from './account/updateAccount'
import updateGroup from './account/updateGroup'
import updateWatchlistPosition from './account/updateWatchlistPosition'


const account = {
  createGroup,
  createWatchlist,
  createWatchlistPosition,
  deleteAccount,
  deleteGroup,
  deleteWatchlist,
  deleteWatchlistPosition,
  fetchPortfolio,
  getAccounts,
  getBaseProjectionData,
  getFullTransactionHistory,
  getGroups,
  getView,
  getMonthlyDividend,
  getIncomeProjection,
  getTransactions,
  setProviderAccountAlias,
  updateAccount,
  updateGroup,
  updateWatchlistPosition
}

export default account