const initialState = {
  authedUser: null,
  config: null,
  isAuthenticated: null,
  notificationMessage: null,
  socket: null,
  isSocketConnected: false,
  pubSubSubscriptions: [],
  blockingMessage: null
}

export { initialState }