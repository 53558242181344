import { find } from 'lodash'

const handler = (action, { alerts }) => {

  const newAlerts = [...alerts]
  const alert = action.payload.alert

  const current = find(newAlerts, { userPriceAlertId: alert.userPriceAlertId })

  if (current) {
    current.alertConfig = alert.alertConfig
  } else {
    newAlerts.push({
      ...alert,
      alertConfig: alert.alertConfig
    })
  }

  return {
    alerts: newAlerts
  }
  
}

export { handler }