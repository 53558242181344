import { head } from 'lodash'
import resolvePreviousClose from './resolvePreviousClose'

const resolveMarketCap = (position, currentMetrics) => {

  const previousClose = resolvePreviousClose(position, currentMetrics)
  return parseFloat(head(position.accounts).SharesOutstanding) * previousClose * 1000000

}

export default resolveMarketCap