export const loadNews = (tickers, source = 'ALL') => ({
  namespace: 'news',
  type: 'loadNews',
  payload: { tickers, source }
})

export const setActiveNews = activeNews => ({
  namespace: 'news',
  type: 'setActiveNews',
  payload: { activeNews }
})

export const setNews = news => ({
  namespace: 'news',
  type: 'setNews',
  payload: { news }
})
