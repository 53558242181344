const handler = (action, { config }) => ({
  config: {
    ...config,
    user_config: {
      ...config.user_config,
      [action.payload.key]: action.payload.value
    }
  }
})

export { handler }