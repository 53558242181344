import { setMarketIndeces } from  './../../../actions/security'
import { loadNewNotifications } from  './../../../actions/notification'
import {
  updatePendingLink,
  updatePendingLinkByProviderAccountId,
  loadPendingLinks,
  setIsFetchingAccounts
} from  './../../../actions/account'

import { head, isEmpty, isNull } from 'lodash'

const handler = async (action, _api, dispatch) => {

  const pubSubMessage = action.payload.pubSubMessage
  const eventName = pubSubMessage.payload.event
  const eventData = pubSubMessage.payload.data

  console.log(pubSubMessage)

  if (eventName === 'notification_created') {
    dispatch(loadNewNotifications)
  }

  if (eventName === 'indeces_updated') {
    dispatch(setMarketIndeces(eventData))
  }

  if (eventName === 'yodlee_refresh_notification_received') {
    const providerAccount = head(eventData.event.data.providerAccount)
    const requestId = providerAccount.requestId
    const lastStatus = head(providerAccount.dataset).additionalStatus
    dispatch(updatePendingLink({ id: requestId, key: 'lastStatus', value: lastStatus }))
  }

  if (eventName === 'portfolio_migration_started') {
    const request = eventData.request
    dispatch(updatePendingLink({ id: request.requestId, key: 'isSentForMigration', value: 1 }))
  }

  if (eventName === 'portfolio_updated') {
    if (!isNull(eventData.requestId)) {
      dispatch(updatePendingLink({ id: eventData.requestId, key: 'arePositionsMigrated', value: isEmpty(eventData.errors) ? 1 : -1 }))
    } else {
      eventData.migratedProviderAccountIds.forEach(providerAccountId => {
        dispatch(updatePendingLinkByProviderAccountId({
          providerAccountId,
          key: 'arePositionsMigrated',
          value: 1
        }))
      })
      eventData.unmigratedProviderAccountIds.forEach(providerAccountId => {
        dispatch(updatePendingLinkByProviderAccountId({
          providerAccountId,
          key: 'arePositionsMigrated',
          value: -1
        }))
      })
    }
  }

  if (eventName === 'portfolio_update_error') {
    if (!isNull(eventData.requestId)) {
      dispatch(updatePendingLink({ id: eventData.requestId, key: 'arePositionsMigrated', value: -1 }))
    } else {
      dispatch(loadPendingLinks)
    }
  }

  if (eventName === 'transactions_updated') {
    if (!isNull(eventData.requestId)) {
      dispatch(updatePendingLink({ id: eventData.requestId, key: 'areTransactionsMigrated', value: isEmpty(eventData.errors) ? 1 : -1 }))
    } else {
      eventData.migratedProviderAccountIds.forEach(providerAccountId => {
        dispatch(updatePendingLinkByProviderAccountId({
          providerAccountId,
          key: 'areTransactionsMigrated',
          value: 1
        }))
      })
      eventData.unmigratedProviderAccountIds.forEach(providerAccountId => {
        dispatch(updatePendingLinkByProviderAccountId({
          providerAccountId,
          key: 'areTransactionsMigrated',
          value: -1
        }))
      })
    }
    dispatch(setIsFetchingAccounts(false))
  }

  if (eventName === 'transactions_update_error') {
    if (!isNull(eventData.requestId)) {
      dispatch(updatePendingLink({ id: eventData.requestId, key: 'areTransactionsMigrated', value: -1 }))
    } else {
      dispatch(loadPendingLinks)
    }
    dispatch(setIsFetchingAccounts(false))
  }

}

export { handler }
