import { find, isEmpty } from "lodash";

const resolveMarketValue = (account, currentMetrics) => {
  let marketValue = account.PosMarketValue;

  const cachedRecord = find(currentMetrics, { ticker: account.PricingSymbol });

  if (!isEmpty(cachedRecord)) {
    marketValue = account.Quantity * cachedRecord.price;

    if (isNaN(marketValue)) {
      marketValue = account.PosMarketValue;
    }

    if (account.Category.toUpperCase() === "OPTIONS") {
      marketValue = marketValue * 100;
    }
  }

  return isNaN(marketValue) ? 0 : marketValue;
};

export default resolveMarketValue;
