const handler = (action, state) => {

  const selectedModule = state.selectedModule

  if (selectedModule === 'manage') {
    return { selectedPositionType: action.payload.selectedPositionType }
  } else {
    return { selectedPositionTypePlan: action.payload.selectedPositionType }
  }

}

export { handler }