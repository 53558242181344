import { setIncomeProjection } from './../../../actions/account'

const handler = async (action, api, dispatch, getState) => {

  const state = getState()
  const addContribute = state.manage.addContribute

  dispatch(setIncomeProjection(null))
  const positions = await api.account.getIncomeProjection({
    reinvestPercent: action.payload.reinvestPercent,
    additionalContribution: addContribute
  })
  dispatch(setIncomeProjection(positions))
  
}

export { handler }