import getPendingLinks from "./yodlee/getPendingLinks";
import getYodleeDetails from "./yodlee/getYodleeDetails";
import setRequestsForDisplay from "./yodlee/setRequestsForDisplay";
import setRequestsNotForDisplay from "./yodlee/setRequestsNotForDisplay";

const account = {
  getPendingLinks,
  getYodleeDetails,
  setRequestsForDisplay,
  setRequestsNotForDisplay,
};

export default account;
