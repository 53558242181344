import { setReadNotifications } from  './../../../actions/notification'

const handler = async (action, api, dispatch) => {

  try {
    // const { page, pageSize } = action.payload
    const readNotifications = await api.notification.getReadNotifications({ page: 1, pageSize: 10 })
    dispatch(setReadNotifications(readNotifications))
  } catch(err) {
    console.log(err)
  }

}

export { handler }
