import { isNull } from 'lodash'
import resolveDayChange from './resolveDayChange'
import resolveGainLoss from './resolveGainLoss'
import resolveMarketValue from './resolveMarketValue'
import resolvePreviousClose from './resolvePreviousClose'
import resolvePreviousMarketValue from './resolvePreviousMarketValue'
import resolvePrice from './resolvePrice'

const getPerformanceTotals = (positions, currentMetrics) => {

  let totalMarketValue = 0
  let totalGainLoss = 0
  let totalDayChange = 0
  let totalCostBasis = 0
  let totalPreviousClose = 0
  let totalPreviousMarketValue = 0
  let totalPrice = 0

  if (!isNull(positions)) {
    positions.forEach(position => {
      totalDayChange += resolveDayChange(position, currentMetrics)
      totalPreviousClose += resolvePreviousClose(position, currentMetrics)
      totalPrice += resolvePrice(position, currentMetrics)
      position.accounts.forEach(account => {
        totalMarketValue += resolveMarketValue(account, currentMetrics)
        totalPreviousMarketValue += resolvePreviousMarketValue(account, currentMetrics)
        totalGainLoss += resolveGainLoss(account, currentMetrics)
        totalCostBasis += account.CostBasis
      })
    })
  }

  return {
    totalMarketValue,
    totalGainLoss,
    totalDayChange,
    totalCostBasis,
    totalPreviousClose,
    totalPreviousMarketValue,
    totalPrice
  }

}

export default getPerformanceTotals
