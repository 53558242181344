
const handler = (action, { accounts }) => {

  const newAccounts = accounts.map(account => {
    if (account.userAccountId !== action.payload.id) {
      return account
    } else {
      return {
        ...account,
        alias: action.payload.alias,
        userAccountGroupId: action.payload.groupId
      } 
    }
  })

  return {
    accounts: newAccounts
  }

}

export { handler }
