import { find, isEmpty } from 'lodash'

import resolveMarketValue from './resolveMarketValue'

const resolvePreviousMarketValue = (account, currentMetrics) => {

  let previousMarketValue = resolveMarketValue(account, currentMetrics)

  const cachedRecord = find(currentMetrics, { ticker: account.PricingSymbol })

  if (!isEmpty(cachedRecord)) {
    previousMarketValue = (cachedRecord.previousClose || cachedRecord.price) * account.Quantity
  }

  if (account.Category.toUpperCase() === 'OPTIONS') {
    previousMarketValue = previousMarketValue * 100
  }

  return previousMarketValue

}

export default resolvePreviousMarketValue