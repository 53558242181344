const { find, isEmpty } = require('lodash')

const resolveAccountName = (account, accounts) => {

  let accountName = account.AccountName

  const selectedAccount = find(accounts, { userAccountId: account.useraccountid })

  if (!isEmpty(selectedAccount)) {
    accountName = selectedAccount.alias || selectedAccount.name
  }

  return accountName

}

export default resolveAccountName