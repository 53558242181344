import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { Provider as StateProvider } from 'react-redux'
import { configureStore } from './state/store'
import services from './services'

import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import App from './views/App'

import config from './config'

Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

ReactDOM.render(
  <BrowserRouter>
    <StateProvider store={configureStore(services)}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <App />
      </QueryParamProvider>
    </StateProvider>
  </BrowserRouter>,
  document.getElementById('root')
)
