import { setSecurity } from './../../../actions/security'

const handler = async (action, api, dispatch) => {

  const { ticker, mode, onComplete } = action.payload

  if (!onComplete) {
    dispatch(setSecurity(null))
  }
  
  const security = await api.security.getSecurity({ ticker, mode })

  if (onComplete) {
    onComplete(security)
  } else {
    dispatch(setSecurity(security))
  }
  
}

export { handler }