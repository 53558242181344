const handler = (action, { groups }) => {

  const newGroups = groups.map(group => {
    if (group.userAccountGroupId !== action.payload.id) {
      return group
    } else {
      return {
        ...group,
        name: action.payload.name
      }      
    }
  })

  return {
    groups: newGroups
  }

}

export { handler }
