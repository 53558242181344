import {
    loadConfig,
    setAuthedUser
  } from  './../../../actions/user'
  
  const handler = async (action, api, dispatch) => {
    const { payload: { onComplete } } = action
    const response = await api.user.setAsFreeSubscription();
    const authedUser = await api.user.getAuthedUser()
    dispatch(setAuthedUser(authedUser))
    dispatch(loadConfig);
    onComplete && onComplete(response)
  }
  export { handler }