import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))

  await api.user.setUserConfig({
    key: 'displaySoldPositions',
    value: action.payload.displaySoldPositions
  })

}

export { handler }
