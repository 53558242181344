import { isEmpty, find } from 'lodash'

const handler = (action, { positionNotes }) => {

  const newPositionNotes = [...positionNotes ]

  const currentNote = find(positionNotes, { userPositionId: action.payload.userPositionId })

  if (isEmpty(currentNote)) {
    newPositionNotes.push({
      userPositionId: action.payload.userPositionId,
      notes: action.payload.note
    })
  } else {
    currentNote.notes = action.payload.note
  }

  return {
    positionNotes: newPositionNotes
  }

}

export { handler }