import { filter, isEmpty, includes, isNull } from 'lodash'
import processData from '../../selectors/manage/getProcessedData/processData'

import { getSelectedPositionType, getSelectedSectors } from '../../selectors/manage'

const handler = action => {

  const state = action.payload.state

  // Base data
  const config = state.user.config
  let positions = null
  if (!isNull(state.account.positions)) {
    positions = filter(state.account.positions.map(position => ({
      ...position,
      accounts: filter(position.accounts, ({ userpositionid }) => !includes(state.plan.deletedPositions, userpositionid))
    })), position => !isEmpty(position.accounts))
  }
  const currentMetrics = state.security.currentMetrics
  const dividendReliability = state.security.dividendReliability

  // Selections
  const selectedKpi = state.manage.selectedKpi
  
  const dividendsOnly = state.manage.dividendsOnly
  const selectedSectors = getSelectedSectors(state)
  const selectedPositionType = getSelectedPositionType(state)
  const displaySoldPositions = state.manage.displaySoldPositions
  const selectedModule = state.manage.selectedModule

  // Account
  let accounts = []
  let selectedAccounts = null
  if (selectedModule === 'manage') {
    accounts = state.account.portfolioAccounts
    selectedAccounts = state.manage.selectedAccounts
  }
  if (state.manage.selectedModule === 'plan') {
    accounts = state.account.planAccounts
    selectedAccounts = state.manage.selectedWatchlists
  }

  if (isNull(selectedAccounts)) {
    selectedAccounts = accounts.map(({ userAccountId }) => userAccountId)
  }

  // Configuration
  const addContribute = state.manage.addContribute
  // const reinvestPercent = state.manage.reinvestPercent

  // Notes
  const positionNotes = state.notes.positionNotes
  const stockNotes = state.notes.stockNotes

  const processedData = processData({
    config,
    positions,
    currentMetrics,
    selectedKpi,
    selectedAccounts,
    selectedSectors,
    selectedPositionType,
    dividendsOnly,
    accounts,
    // baseProjectionData,
    addContribute,
    // reinvestPercent,
    positionNotes,
    stockNotes,
    selectedModule,
    displaySoldPositions,
    dividendReliability
  })
  
  return { processedData }
}

export { handler }