import { find, isEmpty, head } from 'lodash'

import resolvePrice from './resolvePrice'

const resolvePreviousClose = (position, currentMetrics) => {

  let previousClose = resolvePrice(position, currentMetrics)

  const cachedRecord = find(currentMetrics, { ticker: head(position.accounts).PricingSymbol })

  if (!isEmpty(cachedRecord)) {
    previousClose = cachedRecord.previousClose || cachedRecord.price
  }

  return previousClose

}

export default resolvePreviousClose