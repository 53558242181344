import { setNewNotifications } from  './../../../actions/notification'

const handler = async (_action, api, dispatch) => {

  const newNotifications = await api.notification.getNewNotifications()
  dispatch(setNewNotifications(newNotifications))


}

export { handler }
