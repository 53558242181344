import React, { useEffect, useMemo, Suspense, lazy, useState } from "react";
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.cjs.js";

import { useQueryParam, StringParam } from "use-query-params";

import {
  exchangeToken,
  validateAuthenticationStatus,
} from "./../state/actions/user";
import { useDispatch, useSelector } from "react-redux";
import { getIsAuthenticated } from "./../state/selectors/user";

import { isEmpty, isNull } from "lodash";

import { Auth0Provider } from "@auth0/auth0-react";
import config from "./../config";

import "./theme/stylesheets/base.css";

const Dashboard = lazy(() => import("./Dashboard"));
const Authenticating = lazy(() => import("./widgets/Authenticating"));
const Logout = lazy(() => import("./Logout"));
const Idle = lazy(() => import("./widgets/Idle"));
const Welcome = lazy(() => import("./Welcome"));
const SignUp = lazy(() => import("./SignUp"));
const Auth = lazy(() => import("./Auth"));
const BillingPortal = lazy(() => import("./BillingPortal"));
const TickerModal = lazy(() => import("./modules/TickerModal"));

const App = () => {
  const dispatch = useDispatch();

  const [accessToken] = useQueryParam("access_token", StringParam);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [remaining, setRemaining] = useState(0);
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    if (window.location.pathname !== "/auth") {
      if (!isEmpty(accessToken)) {
        dispatch(exchangeToken({ token: accessToken, type: "AUTH0" }));
      } else {
        dispatch(validateAuthenticationStatus);
      }
    }
  }, [accessToken, dispatch]);

  const RenderedComponent = useMemo(() => {
    if (window.location.pathname === "/auth") {
      return Auth;
    }
    if (window.location.pathname === "/signup") {
      return () => <SignUp mode="signUp" />;
    }
    if (window.location.pathname === "/login") {
      return () => <SignUp mode="login" />;
    }
    if (window.location.pathname === "/logout") {
      return Logout;
    }
    if (window.location.pathname === "/ticker_modal") {
      return () => <TickerModal isModal={true} showAddToWatchlist={false} />;
    }
    if (window.location.pathname === "/billing_portal") {
      return BillingPortal;
    }

    if (isNull(isAuthenticated)) {
      return Authenticating;
    } else {
      if (isAuthenticated === true) {
        return Dashboard;
      }
    }

    if (
      window.location.origin === "http://localhost:3000" ||
      window.location.origin === "https://dev.figi360.com" ||
      window.location.origin === "https://dev.dividends360.com" ||
      window.location.origin ===
        "https://jolos-mac-studio-1.manatee-hammerhead.ts.net"
    ) {
      return Welcome;
    }
    window.location.href = `${config.LOGOUT_URL}/?logout=true`;
    return () => null;
  }, [isAuthenticated]);

  const onIdle = () => {
    if (isAuthenticated) setIsIdle(true);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1800000,
    throttle: 500,
  });

  return (
    <Auth0Provider
      domain={config.AUTH0_DOMAIN}
      clientId={config.AUTH0_CLIENT_ID}
    >
      <Suspense fallback={null}>
        {isIdle ? <Idle setIsIdle={setIsIdle} /> : <RenderedComponent />}
      </Suspense>
    </Auth0Provider>
  );
};

export default App;
