import {
  setPositions,
  loadMonthlyDividend,
  loadIncomeProjection,
  loadTransactions,
} from "./../../../actions/account";
import {
  loadCurrentPrices,
  loadDividendReliability,
  subscribeToRealTimeQuotes,
} from "./../../../actions/security";

const handler = async (_action, api, dispatch) => {
  // dispatch(setPositions(null))

  const view = await api.account.getView();

  dispatch(loadCurrentPrices(view.pricingSymbols));
  dispatch(setPositions(view.positions));
  dispatch(loadDividendReliability({ ticker: view.dividendTickers }));
  dispatch(loadMonthlyDividend);
  dispatch(subscribeToRealTimeQuotes({ pricingSymbols: view.pricingSymbols }));
  dispatch(loadIncomeProjection);
  dispatch(loadTransactions);
};

export { handler };
