const handler = (_action, { pendingLinks }) => {

  const newPendingLinks = pendingLinks.map(link => ({
    ...link,
    isSentForMigration: link.isSentForMigration === -1 ? 0 : link.isSentForMigration,
    arePositionsMigrated: link.arePositionsMigrated === -1 ? 0 : link.arePositionsMigrated,
    areTransactionsMigrated: link.areTransactionsMigrated === -1 ? 0 : link.areTransactionsMigrated
  }))

  return {
    pendingLinks: newPendingLinks
  }
  
}

export { handler }
