import { setDividendDetails } from './../../../actions/security'

const handler = async (action, api, dispatch) => {

  const { ticker, onComplete } = action.payload

  if (!onComplete) {
    dispatch(setDividendDetails(null))
  }
  
  const dividendDetails = await api.security.getDividendDetails({ ticker })

  if (onComplete) {
    onComplete(dividendDetails)
  } else {
    dispatch(setDividendDetails(dividendDetails))
  }
  
}

export { handler }