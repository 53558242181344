import { sortBy } from 'lodash'

const handler = action => {

  const accountList = action.payload.accounts.map(account => ({
    ...account,
    displayName: account.alias || account.name
  }))

  return {
    accounts: sortBy(accountList, 'displayName')
  }

}

export { handler }
