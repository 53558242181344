import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))

  const state = getState()

  await api.user.setUserConfig({
    key: state.manage.selectedModule === 'manage' ? 'selectedPositionType' : 'selectedPositionTypePlan',
    value: action.payload.selectedPositionType
  })

}

export { handler }
