const config = {
  APP_STAGE: "dev",
  AUTH0_DOMAIN: "dividends360.us.auth0.com",
  AUTH0_CLIENT_ID: "6oxSnkP5KhGSPLS014G6a4hmPx7d6f8u",
  LOGOUT_URL: "https://dev.dividends360.com",
  RPC_API_URL: "https://sls-scus-dev-b58a59-apim.azure-api.net/rpc",
  YODLEE_API_URL: "https://production.api.yodlee.com/ysl",
  SENTRY_DSN: "https://526f2df07bcd4f748b4912b459f3f6bb@o556171.ingest.sentry.io/5941235",
  SOCKET_SERVER_URL: "https://d360-socket-server.azurewebsites.net",
  TNC_URL: "https://figi360.com/terms-and-conditions",
  PLANNING_URL: "https://planning.dev.dividends360.com",
  FRESHDESK_API_KEY: "<FRESHDESK_API_KEY>",
};

export default config;
