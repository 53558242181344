
const handler = async (action, api) => {

  const { userPositionId, qty, onComplete } = action.payload

  const response = await api.account.updateWatchlistPosition({ userPositionId, qty })
 
  onComplete && onComplete(response)


}

export { handler }