import getAppSettings from './global/getAppSettings'

import { isNull, sortBy } from 'lodash'

export const getCurrentMetrics = state => state.security.currentMetrics

export const getMarketIndeces = state => state.security.marketIndeces

export const getSectors = state => {
  
  const sectors = getAppSettings(state, 'sectors')
  return isNull(sectors) ? [] : sectors

}

export const getIndustries = state => getAppSettings(state, 'industries')
export const getSecurityTypes = state => getAppSettings(state, 'security_types')

export const getSuperSectors = state => sortBy(getAppSettings(state, 'supersector_sorting'), 'SortOrder')

export const getSecurity = state => state.security.security
export const getSecurityDividendHistory = state => state.security.dividendHistory
export const getSecurityDividendStreak = state => state.security.dividendStreak
export const getDividendDetails = state => state.security.dividendDetails

export const getAutoComplete = state => state.security.autoComplete
export const getFinancials = state => state.security.financials
export const getFinancialsSummary = state => state.security.financialsSummary
export const getDividendReliability = state => state.security.dividendReliability