import { isEmpty } from 'lodash'

const handler = (action, { accounts, positions }) => {

  const newAccounts = []
  const newPositions = []

  for (let account of accounts) {
    if (account.userAccountId !== action.payload.id) {
      newAccounts.push(account)
    }
  }

  for (let position of positions) {
    
    const newPosition = { ...position }
    newPosition.accounts = []

    for (let account of position.accounts) {
      if (account.useraccountid !== action.payload.id) {
        newPosition.accounts.push(account)
      }
    }

    if (!isEmpty(newPosition.accounts)) {
      newPositions.push(newPosition)
    }

  }

  return {
    accounts: newAccounts,
    positions: newPositions
  }

}

export { handler }
