import filterPositions from "./filterPositions";

import getPerformanceTotals from "./getPerformanceTotals";
import getAnnualIncomeTotals from "./getAnnualIncomeTotals";
import getYieldTotals from "./getYieldTotals";

import getTypeSort from "./getTypeSort";
import resolveDayChange from "./resolveDayChange";
import resolveGainLoss from "./resolveGainLoss";
import resolveMarketValue from "./resolveMarketValue";
import resolvePrice from "./resolvePrice";
import resolvePreviousClose from "./resolvePreviousClose";
import resolvePreviousMarketValue from "./resolvePreviousMarketValue";
import resolveYield from "./resolveYield";
import resolveAccountName from "./resolveAccountName";
import resolveMarketCap from "./resolveMarketCap";
import resolvePositionNote from "./resolvePositionNote";
import resolveStockNote from "./resolveStockNote";

// import generateIncomeProjection from './generateIncomeProjection'

import millify from "millify";

import {
  nullOnZero,
  numberOrNull,
  toCurrency,
  toDate,
  toPercent,
  toProperNumber,
  valueOrNull,
  zeroOnIsNan,
} from "./helpers";

import {
  head,
  isNull,
  sortBy,
  find,
  isEmpty,
  isEqual,
  startCase,
} from "lodash";
import moment from "moment";

const processData = ({
  config,
  positions,
  currentMetrics,
  selectedKpi,
  selectedAccounts,
  selectedSectors,
  selectedPositionType,
  dividendsOnly,
  accounts,
  // baseProjectionData,
  addContribute,
  // reinvestPercent,
  positionNotes,
  stockNotes,
  displaySoldPositions,
  dividendReliability,
  deletedPositions,
}) => {
  if (isNull(positions) || isNull(config)) {
    return [];
  }

  let appSettings = {};
  if ("app_settings" in config) {
    appSettings = config.app_settings;
  }

  const filteredPositions = filterPositions(positions, {
    selectedKpi,
    selectedAccounts,
    selectedSectors,
    selectedPositionType,
    dividendsOnly,
    displaySoldPositions,
    deletedPositions,
  });

  const allPositions = filterPositions(positions, {
    selectedKpi: null,
    selectedAccounts,
    selectedSectors: null,
    selectedPositionType: null,
    dividendsOnly: null,
    displaySoldPositions,
    deletedPositions,
  });

  // PROCEED ONLY WHEN relevant data is changed
  const payload = {
    positions,
    filteredPositions,
    currentMetrics,
    addContribute,
    // reinvestPercent,
    positionNotes,
    stockNotes,
    displaySoldPositions,
    dividendReliability,
  };

  if (isEqual(payload, window["_CACHED_PAYLOAD"])) {
    return window["_CACHED_DISPLAY"];
  } else {
    window["_CACHED_PAYLOAD"] = payload;

    // Performance
    const performanceTotals = getPerformanceTotals(
      filteredPositions,
      currentMetrics
    );
    const grandTotalMarketValue = performanceTotals.totalMarketValue;

    const performanceTotalsAll = getPerformanceTotals(
      allPositions,
      currentMetrics
    );
    const grandTotalMarketValueAll = performanceTotalsAll.totalMarketValue;

    // Annual Income
    const annualIncomeTotals = getAnnualIncomeTotals(filteredPositions);
    const grandTotalEstYrIncome = annualIncomeTotals.totalEstYrIncome;
    const grandTotalPaidYtd = annualIncomeTotals.totalPaidYtd;
    const grandTotalRemaining = annualIncomeTotals.totalRemaining;
    const grandTotalFeesYtd = annualIncomeTotals.totalFeesYtd;

    // Future Income
    const yieldTotals = getYieldTotals(allPositions, currentMetrics);
    const portfolioYield = yieldTotals.totalCurrentYield / 100;

    const data = filteredPositions.map((position) => {
      const firstAccount = head(position.accounts);

      let totalShares = 0;
      let totalMarketValue = 0;
      let totalPreviousMarketValue = 0;
      let totalCostBasis = 0;
      // let totalCostPerShare = 0

      let totalGainLoss = 0;
      if (isNull(firstAccount["Gain/Loss"])) {
        totalGainLoss = null;
      }

      let totalEstimateYrIncome = 0;
      let totalFeesYtd = 0;
      let totalPaidYtd = 0;
      let totalRemaining = 0;

      const category = firstAccount.Category;

      const price = resolvePrice(position, currentMetrics);
      const dayChange = resolveDayChange(position, currentMetrics);
      const previousClose = resolvePreviousClose(position, currentMetrics);
      const marketCap = resolveMarketCap(position, currentMetrics);

      const percentDayChange =
        ((price - previousClose) / Math.abs(previousClose)) * 100;

      const priceChange = price - previousClose;

      const wk52High = numberOrNull(parseFloat(firstAccount["52weekhigh"]));
      const wk52Low = numberOrNull(parseFloat(firstAccount["52weeklow"]));

      const yrDivRate = numberOrNull(firstAccount.EstAnnualDiv);
      const estimatedYield = numberOrNull(
        resolveYield(position, currentMetrics)
      );

      const currentDivAmt = numberOrNull(firstAccount.CurrentDiv);
      const currentDivCurOrig = firstAccount.CurrentDivOrigCurrency;
      const currentDivAmtOrig =
        currentDivCurOrig !== "USD"
          ? numberOrNull(firstAccount.CurrentDivOrigAmount)
          : null;

      // const lastDivAmt = numberOrNull(firstAccount.LastDivAmt)

      const isDividendPaying =
        firstAccount.divstatus === "Dividend" ||
        firstAccount.Type.toUpperCase() === "PREFERRED";
      const isCash =
        firstAccount.Sector.toUpperCase() === "CASH" ||
        firstAccount.Sector.toUpperCase() === "CRYP";
      const isForeign = isDividendPaying && currentDivCurOrig !== "USD";

      const totalMonthPayments = [];
      const totalMonthEstimates = [];
      const totalMonthDeclarations = [];
      for (let month = 1; month <= 12; month++) {
        totalMonthPayments.push({ month, amount: 0 });
        totalMonthEstimates.push({ month, amount: 0 });
        totalMonthDeclarations.push({ month, amount: 0 });
      }

      const totalProjectedIncome1YrCAGR_Reinvest = [];
      const totalProjectedIncome3YrCAGR_Reinvest = [];
      const totalProjectedIncome5YrCAGR_Reinvest = [];
      const totalProjectedIncome10YrCAGR_Reinvest = [];

      const totalProjectedIncome1YrCAGR_NoReinvest = [];
      const totalProjectedIncome3YrCAGR_NoReinvest = [];
      const totalProjectedIncome5YrCAGR_NoReinvest = [];
      const totalProjectedIncome10YrCAGR_NoReinvest = [];

      const totalProjectedIncome1YrCAGR_Current = [];
      const totalProjectedIncome3YrCAGR_Current = [];
      const totalProjectedIncome5YrCAGR_Current = [];
      const totalProjectedIncome10YrCAGR_Current = [];

      const totalProjectedIncome1YrCAGR_Contrib = [];
      const totalProjectedIncome3YrCAGR_Contrib = [];
      const totalProjectedIncome5YrCAGR_Contrib = [];
      const totalProjectedIncome10YrCAGR_Contrib = [];

      let growthRate1Y = null;
      let growthRate3Y = null;
      let growthRate5Y = null;
      let growthRate10Y = null;

      let growthRate1YOrig = null;
      let growthRate3YOrig = null;
      let growthRate5YOrig = null;
      let growthRate10YOrig = null;

      let growthRate1YConvertedReason = null;
      let growthRate3YConvertedReason = null;
      let growthRate5YConvertedReason = null;
      let growthRate10YConvertedReason = null;

      const div_growth_rate_alternate_1yr = [];
      const div_growth_rate_alternate_yr_1yr = [];
      const div_growth_rate_alternate_3yr = [];
      const div_growth_rate_alternate_yr_3yr = [];
      const div_growth_rate_alternate_5yr = [];
      const div_growth_rate_alternate_yr_5yr = [];
      const div_growth_rate_alternate_10yr = [];
      const div_growth_rate_alternate_yr_10yr = [];

      if ("IncomeProjection" in firstAccount) {
        if ("1YR" in firstAccount.IncomeProjection) {
          growthRate1Y = growthRate1YOrig =
            head(firstAccount.IncomeProjection["1YR"]).DivGrowthRate * 100;
          if (
            !isNull(
              head(firstAccount.IncomeProjection["1YR"]).DivGrowthRateAlternate
            )
          ) {
            growthRate1Y =
              head(firstAccount.IncomeProjection["1YR"])
                .DivGrowthRateAlternate * 100;
            growthRate1YConvertedReason = head(
              firstAccount.IncomeProjection["1YR"]
            ).DivGrowthRateAlternateYR;
          }

          for (let projection of firstAccount.IncomeProjection["1YR"]) {
            div_growth_rate_alternate_1yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternate * 100,
            });
            div_growth_rate_alternate_yr_1yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternateYR,
            });
          }
        }

        if ("3YR" in firstAccount.IncomeProjection) {
          growthRate3Y = growthRate3YOrig =
            head(firstAccount.IncomeProjection["3YR"]).DivGrowthRate * 100;
          if (
            !isNull(
              head(firstAccount.IncomeProjection["3YR"]).DivGrowthRateAlternate
            )
          ) {
            growthRate3Y =
              head(firstAccount.IncomeProjection["3YR"])
                .DivGrowthRateAlternate * 100;
            growthRate3YConvertedReason = head(
              firstAccount.IncomeProjection["3YR"]
            ).DivGrowthRateAlternateYR;
          }

          for (let projection of firstAccount.IncomeProjection["3YR"]) {
            div_growth_rate_alternate_3yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternate * 100,
            });
            div_growth_rate_alternate_yr_3yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternateYR,
            });
          }
        }

        if ("5YR" in firstAccount.IncomeProjection) {
          growthRate5Y = growthRate5YOrig =
            head(firstAccount.IncomeProjection["5YR"]).DivGrowthRate * 100;
          if (
            !isNull(
              head(firstAccount.IncomeProjection["5YR"]).DivGrowthRateAlternate
            )
          ) {
            growthRate5Y =
              head(firstAccount.IncomeProjection["5YR"])
                .DivGrowthRateAlternate * 100;
            growthRate5YConvertedReason = head(
              firstAccount.IncomeProjection["5YR"]
            ).DivGrowthRateAlternateYR;
          }

          for (let projection of firstAccount.IncomeProjection["5YR"]) {
            div_growth_rate_alternate_5yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternate * 100,
            });
            div_growth_rate_alternate_yr_5yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternateYR,
            });
          }
        }

        if ("10YR" in firstAccount.IncomeProjection) {
          growthRate10Y = growthRate10YOrig =
            head(firstAccount.IncomeProjection["10YR"]).DivGrowthRate * 100;
          if (
            !isNull(
              head(firstAccount.IncomeProjection["10YR"]).DivGrowthRateAlternate
            )
          ) {
            growthRate10Y =
              head(firstAccount.IncomeProjection["10YR"])
                .DivGrowthRateAlternate * 100;
            growthRate10YConvertedReason = head(
              firstAccount.IncomeProjection["10YR"]
            ).DivGrowthRateAlternateYR;
          }

          for (let projection of firstAccount.IncomeProjection["10YR"]) {
            div_growth_rate_alternate_10yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternate * 100,
            });
            div_growth_rate_alternate_yr_10yr.push({
              year: projection.YearNum,
              value: projection.DivGrowthRateAlternateYR,
            });
          }
        }
      }

      let hasNote = false;
      let ReliabilityRating = null;
      let ReliabilityScore = null;

      let DivGrowthStreak = null;
      let DivPayoutStreak = null;
      let DivNonReducedStreak = null;
      let DivGrowthVelocity = null;
      if (!isNull(growthRate1Y) && !isNull(growthRate3Y)) {
        if (growthRate1Y === 0 || growthRate3Y === 0) {
          DivGrowthVelocity = 0;
        } else {
          DivGrowthVelocity = growthRate1Y / growthRate3Y;
        }
      }

      let Yield_1yrAvg = null;
      let Yield_3yrAvg = null;
      let Yield_5yrAvg = null;
      let Yield_10yrAvg = null;

      let PayoutRatio = null;

      if (!isNull(dividendReliability)) {
        const reliability = find(dividendReliability, {
          ticker: firstAccount.Ticker,
        });
        if (!isEmpty(reliability)) {
          ReliabilityRating = reliability.reliability.ReliabilityRating;
          ReliabilityScore = parseFloat(
            reliability.reliability.ReliabilityScore
          );
          DivGrowthStreak = parseInt(reliability.streak.growthYears);
          DivPayoutStreak = parseInt(reliability.streak.paidYears);
          DivNonReducedStreak = parseInt(reliability.streak.nonReducedYears);
          if ("AvgYields" in reliability) {
            Yield_1yrAvg = parseFloat(reliability.AvgYields.Yld1Avg);
            Yield_3yrAvg = parseFloat(reliability.AvgYields.Yld3Avg);
            Yield_5yrAvg = parseFloat(reliability.AvgYields.Yld5Avg);
            Yield_10yrAvg = parseFloat(reliability.AvgYields.Yld10Avg);
          }
          const metric = find(reliability.reliability_details, {
            MetricType: "PayoutRatio",
          });
          if (!isEmpty(metric)) {
            PayoutRatio = metric.MetricValueRaw * 100;
          }
        }
      }

      const has1YrAvgYield = Yield_1yrAvg !== null && Yield_1yrAvg !== 0;
      const has3YrAvgYield = Yield_3yrAvg !== null && Yield_3yrAvg !== 0;
      const has5YrAvgYield = Yield_5yrAvg !== null && Yield_5yrAvg !== 0;
      const has10YrAvgYield = Yield_10yrAvg !== null && Yield_10yrAvg !== 0;

      let justAdded;
      if (position.accounts.length === 1) {
        if (
          firstAccount.IsInWatchlist &&
          moment()
            .startOf("day")
            .isSame(moment(firstAccount.UPCreatedAt).startOf("day"))
        ) {
          justAdded = true;
        }
      }

      return {
        subRows: sortBy(
          position.accounts.map((account) => {
            const marketValue = resolveMarketValue(account, currentMetrics);
            const previousMarketValue = resolvePreviousMarketValue(
              account,
              currentMetrics
            );

            const gainLoss = resolveGainLoss(account, currentMetrics);

            const accountDayChange = resolveDayChange(
              position,
              currentMetrics,
              account
            );

            totalShares += account.Quantity;
            totalMarketValue += marketValue;
            totalPreviousMarketValue += previousMarketValue;
            totalCostBasis += account.CostBasis;
            // totalCostPerShare += account.Cost

            if (!isNull(totalGainLoss)) {
              totalGainLoss += resolveGainLoss(account, currentMetrics);
            }

            const percentGainLoss =
              account.CostBasis !== 0
                ? (gainLoss / Math.abs(account.CostBasis)) * 100
                : 0;

            let estYrIncome = null;

            if (!isNull(totalEstimateYrIncome)) {
              estYrIncome = isNaN(account.EstIncome) ? 0 : account.EstIncome;
              totalEstimateYrIncome += estYrIncome;
            }

            let paidYtd = null;
            if (!isNull(totalPaidYtd)) {
              paidYtd = isNaN(account.PaidYTD) ? 0 : account.PaidYTD;
              totalPaidYtd += paidYtd;
            }

            let feesYtd = null;
            if (!isNull(totalFeesYtd)) {
              feesYtd = isNaN(account.FeesYTD) ? 0 : account.FeesYTD;
              totalFeesYtd += feesYtd;
            }

            let remaining = null;
            if (!isNull(totalRemaining)) {
              remaining = isNaN(account.Remaining) ? 0 : account.Remaining;
              totalRemaining += remaining;
            }

            const monthPayments = [];
            const monthEstimates = [];
            const monthDeclarations = [];
            for (let month = 1; month <= 12; month++) {
              monthPayments.push({ month, amount: 0 });
              monthEstimates.push({ month, amount: 0 });
              monthDeclarations.push({ month, amount: 0 });
            }

            if ("DivHistory" in account) {
              for (let income of account.DivHistory) {
                const monthlyPayment = find(monthPayments, {
                  month: income.DivPayMonth,
                });
                monthlyPayment.amount += income.MonthlyDivAmount;

                const totalMonthPayment = find(totalMonthPayments, {
                  month: income.DivPayMonth,
                });
                totalMonthPayment.amount += income.MonthlyDivAmount;

                const monthlyEstimate = find(monthEstimates, {
                  month: income.DivPayMonth,
                });
                monthlyEstimate.amount += income.MonthlyDivEstimated;

                const totalMonthEstimate = find(totalMonthEstimates, {
                  month: income.DivPayMonth,
                });
                totalMonthEstimate.amount += income.MonthlyDivEstimated;

                const monthlyDeclared = find(monthDeclarations, {
                  month: income.DivPayMonth,
                });
                monthlyDeclared.amount += income.MonthlyDivDeclared;

                const totalMonthDeclaration = find(totalMonthDeclarations, {
                  month: income.DivPayMonth,
                });
                totalMonthDeclaration.amount += income.MonthlyDivDeclared;
              }
            }

            const year_income_projection_1ycagr_reinvest = [];
            const year_income_projection_3ycagr_reinvest = [];
            const year_income_projection_5ycagr_reinvest = [];
            const year_income_projection_10ycagr_reinvest = [];

            const year_income_projection_1ycagr_no_reinvest = [];
            const year_income_projection_3ycagr_no_reinvest = [];
            const year_income_projection_5ycagr_no_reinvest = [];
            const year_income_projection_10ycagr_no_reinvest = [];

            const year_income_projection_1ycagr_current = [];
            const year_income_projection_3ycagr_current = [];
            const year_income_projection_5ycagr_current = [];
            const year_income_projection_10ycagr_current = [];

            const year_income_projection_1ycagr_contrib = [];
            const year_income_projection_3ycagr_contrib = [];
            const year_income_projection_5ycagr_contrib = [];
            const year_income_projection_10ycagr_contrib = [];

            const accountName = resolveAccountName(account, accounts);
            const percentHoldings =
              (marketValue / grandTotalMarketValueAll) * 100;

            // WITH DGR and REINVESTMENT
            if ("IncomeProjection" in account) {
              const incomeField = "IncomeReinvest";
              const growthAmountField = "GrowthAmountReinvest";

              if ("1YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["1YR"]) {
                  year_income_projection_1ycagr_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome1YrCAGR_Reinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome1YrCAGR_Reinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("3YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["3YR"]) {
                  year_income_projection_3ycagr_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome3YrCAGR_Reinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome3YrCAGR_Reinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("5YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["5YR"]) {
                  year_income_projection_5ycagr_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome5YrCAGR_Reinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome5YrCAGR_Reinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("10YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["10YR"]) {
                  year_income_projection_10ycagr_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome10YrCAGR_Reinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome10YrCAGR_Reinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }
            }

            // WITH DGR
            if ("IncomeProjection" in account) {
              const incomeField = "IncomeNoReinvest";
              const growthAmountField = "GrowthAmountNoReinvest";

              if ("1YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["1YR"]) {
                  year_income_projection_1ycagr_no_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome1YrCAGR_NoReinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome1YrCAGR_NoReinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("3YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["3YR"]) {
                  year_income_projection_3ycagr_no_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome3YrCAGR_NoReinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome3YrCAGR_NoReinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("5YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["5YR"]) {
                  year_income_projection_5ycagr_no_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome5YrCAGR_NoReinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome5YrCAGR_NoReinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("10YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["10YR"]) {
                  year_income_projection_10ycagr_no_reinvest.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome10YrCAGR_NoReinvest,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome10YrCAGR_NoReinvest.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }
            }

            // WITH DGR, RI, and CONTRIB
            if ("IncomeProjection" in account) {
              const incomeField = "IncomeReinvest";
              const growthAmountField = "GrowthAmountReinvest";
              const contribution = addContribute * (percentHoldings / 100);

              if ("1YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["1YR"]) {
                  const income = projection[incomeField] + contribution;
                  const growth_amount =
                    projection[growthAmountField] + contribution;
                  year_income_projection_1ycagr_contrib.push({
                    year: projection.YearNum,
                    income,
                    growth_amount,
                  });
                  const totalProjection = find(
                    totalProjectedIncome1YrCAGR_Contrib,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome1YrCAGR_Contrib.push({
                      year: projection.YearNum,
                      income,
                      growth_amount,
                    });
                  } else {
                    totalProjection.income += income;
                    totalProjection.growth_amount += growth_amount;
                  }
                }
              }

              if ("3YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["3YR"]) {
                  const income = projection[incomeField] + contribution;
                  const growth_amount =
                    projection[growthAmountField] + contribution;
                  year_income_projection_3ycagr_contrib.push({
                    year: projection.YearNum,
                    income,
                    growth_amount,
                  });
                  const totalProjection = find(
                    totalProjectedIncome3YrCAGR_Contrib,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome3YrCAGR_Contrib.push({
                      year: projection.YearNum,
                      income,
                      growth_amount,
                    });
                  } else {
                    totalProjection.income += income;
                    totalProjection.growth_amount += growth_amount;
                  }
                }
              }

              if ("5YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["5YR"]) {
                  const income = projection[incomeField] + contribution;
                  const growth_amount =
                    projection[growthAmountField] + contribution;
                  year_income_projection_5ycagr_contrib.push({
                    year: projection.YearNum,
                    income,
                    growth_amount,
                  });
                  const totalProjection = find(
                    totalProjectedIncome5YrCAGR_Contrib,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome5YrCAGR_Contrib.push({
                      year: projection.YearNum,
                      income,
                      growth_amount,
                    });
                  } else {
                    totalProjection.income += income;
                    totalProjection.growth_amount += growth_amount;
                  }
                }
              }

              if ("10YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["10YR"]) {
                  const income = projection[incomeField] + contribution;
                  const growth_amount =
                    projection[growthAmountField] + contribution;
                  year_income_projection_10ycagr_contrib.push({
                    year: projection.YearNum,
                    income,
                    growth_amount,
                  });
                  const totalProjection = find(
                    totalProjectedIncome10YrCAGR_Contrib,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome10YrCAGR_Contrib.push({
                      year: projection.YearNum,
                      income,
                      growth_amount,
                    });
                  } else {
                    totalProjection.income += income;
                    totalProjection.growth_amount += growth_amount;
                  }
                }
              }
            }

            if ("IncomeProjection" in account) {
              const incomeField = "Income";
              const growthAmountField = "GrowthAmount";

              if ("1YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["1YR"]) {
                  year_income_projection_1ycagr_current.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome1YrCAGR_Current,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome1YrCAGR_Current.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("3YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["3YR"]) {
                  year_income_projection_3ycagr_current.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome3YrCAGR_Current,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome3YrCAGR_Current.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("5YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["5YR"]) {
                  year_income_projection_5ycagr_current.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome5YrCAGR_Current,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome5YrCAGR_Current.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }

              if ("10YR" in account.IncomeProjection) {
                for (let projection of account.IncomeProjection["10YR"]) {
                  year_income_projection_10ycagr_current.push({
                    year: projection.YearNum,
                    income: projection[incomeField],
                    growth_amount: projection[growthAmountField],
                  });
                  const totalProjection = find(
                    totalProjectedIncome10YrCAGR_Current,
                    { year: projection.YearNum }
                  );
                  if (isEmpty(totalProjection)) {
                    totalProjectedIncome10YrCAGR_Current.push({
                      year: projection.YearNum,
                      income: projection[incomeField],
                      growth_amount: projection[growthAmountField],
                    });
                  } else {
                    totalProjection.income += projection[incomeField];
                    totalProjection.growth_amount +=
                      projection[growthAmountField];
                  }
                }
              }
            }

            const position_note = resolvePositionNote(account, positionNotes);

            if (!isEmpty(position_note)) {
              hasNote = true;
            }

            let justAdded = false;
            if (
              account.IsInWatchlist &&
              moment()
                .startOf("day")
                .isSame(moment(account.UPCreatedAt).startOf("day"))
            ) {
              justAdded = true;
            }

            // Per Account Data
            return {
              _ROW_TYPE: "ACCOUNT_POSITION",
              _ROW_IDENT: account.userpositionid,
              account_id: account.useraccountid,
              cost: valueOrNull(
                account.CostBasis,
                toCurrency(account.CostBasis)
              ),
              cost_float: account.CostBasis,
              cost_per_share: valueOrNull(
                account.Cost,
                toCurrency(account.Cost)
              ),
              cost_per_share_float: account.Cost,
              created_at: account.UPCreatedAt,
              day_change: isCash
                ? null
                : valueOrNull(accountDayChange, toCurrency(accountDayChange)),
              day_change_float: isCash ? null : accountDayChange,
              day_change_percent: justAdded
                ? toPercent(0)
                : zeroOnIsNan(percentDayChange, toPercent(percentDayChange)),
              day_change_percent_float: justAdded
                ? 0
                : zeroOnIsNan(percentDayChange),
              dividend_history: account.DivHistory,
              dividend_payments: account.DividendPayments,
              estimate_yr_income: isDividendPaying
                ? valueOrNull(estYrIncome, toCurrency(estYrIncome))
                : null,
              estimate_yr_income_float: isDividendPaying
                ? valueOrNull(estYrIncome)
                : null,
              estimate_yr_income_percent: isDividendPaying
                ? valueOrNull(
                    estYrIncome,
                    toPercent((estYrIncome / grandTotalEstYrIncome) * 100)
                  )
                : null,
              estimate_percent_income: isDividendPaying
                ? valueOrNull(
                    estYrIncome,
                    toPercent((estYrIncome / grandTotalEstYrIncome) * 100)
                  )
                : null,
              estimate_percent_income_float: isDividendPaying
                ? valueOrNull(estYrIncome)
                : null,
              gain_loss: isCash
                ? null
                : valueOrNull(gainLoss, toCurrency(gainLoss)),
              gain_loss_float: isCash ? null : gainLoss,
              gain_loss_percent: isCash
                ? null
                : valueOrNull(gainLoss, toPercent(percentGainLoss)),
              gain_loss_percent_float: isCash
                ? null
                : valueOrNull(gainLoss, percentGainLoss),
              income_projection: account.IncomeProjection,
              income_to_market_value_ratio: isDividendPaying
                ? toPercent(
                    zeroOnIsNan(
                      estYrIncome / grandTotalEstYrIncome / percentHoldings
                    )
                  )
                : null,
              is_dormant:
                "RecordType" in account &&
                account.RecordType !== "UserPositionsExists",
              market_value: toCurrency(marketValue),
              month_div_declarations: monthDeclarations,
              month_div_estimates: monthEstimates,
              month_div_payments: monthPayments,
              non_income_impact: isDividendPaying
                ? null
                : toCurrency(marketValue * portfolioYield),
              non_income_impact_float: isDividendPaying
                ? null
                : marketValue * portfolioYield,
              notes: resolvePositionNote(account, positionNotes),
              percent_holdings: toPercent(percentHoldings),
              percent_holdings_float: percentHoldings,
              position_id: account.userpositionid,
              position_note,
              previous_close_float: previousClose,
              previous_market_value_float: previousMarketValue,
              projected_income_1yr_reinvest:
                year_income_projection_1ycagr_reinvest,
              projected_income_3yr_reinvest:
                year_income_projection_3ycagr_reinvest,
              projected_income_5yr_reinvest:
                year_income_projection_5ycagr_reinvest,
              projected_income_10yr_reinvest:
                year_income_projection_10ycagr_reinvest,
              projected_income_1yr_no_reinvest:
                year_income_projection_1ycagr_no_reinvest,
              projected_income_3yr_no_reinvest:
                year_income_projection_3ycagr_no_reinvest,
              projected_income_5yr_no_reinvest:
                year_income_projection_5ycagr_no_reinvest,
              projected_income_10yr_no_reinvest:
                year_income_projection_10ycagr_no_reinvest,
              projected_income_1yr_current:
                year_income_projection_1ycagr_current,
              projected_income_3yr_current:
                year_income_projection_3ycagr_current,
              projected_income_5yr_current:
                year_income_projection_5ycagr_current,
              projected_income_10yr_current:
                year_income_projection_10ycagr_current,
              projected_income_1yr_contrib:
                year_income_projection_1ycagr_contrib,
              projected_income_3yr_contrib:
                year_income_projection_3ycagr_contrib,
              projected_income_5yr_contrib:
                year_income_projection_5ycagr_contrib,
              projected_income_10yr_contrib:
                year_income_projection_10ycagr_contrib,
              sell_year: isNull(account.SoldTickerLastTrxDate)
                ? null
                : parseInt(
                    moment(account.SoldTickerLastTrxDate).format("YYYY")
                  ),
              shares: isCash
                ? null
                : valueOrNull(
                    account.Quantity,
                    toProperNumber(account.Quantity)
                  ),
              ticker: accountName,
              trades: account.Trades,
              total_fees_ytd: isDividendPaying
                ? valueOrNull(feesYtd, toCurrency(feesYtd))
                : null,
              total_fees_ytd_percent: isDividendPaying
                ? valueOrNull(
                    feesYtd / grandTotalFeesYtd,
                    toPercent((feesYtd / grandTotalFeesYtd) * 100)
                  )
                : null,
              total_paid_ytd: isDividendPaying
                ? valueOrNull(paidYtd, toCurrency(paidYtd))
                : null,
              total_paid_ytd_percent: isDividendPaying
                ? valueOrNull(
                    paidYtd / grandTotalPaidYtd,
                    toPercent((paidYtd / grandTotalPaidYtd) * 100)
                  )
                : null,
              total_remaining: isDividendPaying
                ? valueOrNull(remaining, toCurrency(remaining))
                : null,
              total_remaining_percent: isDividendPaying
                ? valueOrNull(
                    remaining / grandTotalRemaining,
                    toPercent((remaining / grandTotalRemaining) * 100)
                  )
                : null,
              upcoming_payments: account.UpcomingPayments,
              yield_at_cost: isDividendPaying
                ? account.YieldatCost === 0
                  ? null
                  : valueOrNull(
                      account.YieldatCost,
                      toPercent(account.YieldatCost)
                    )
                : null,
              yield_at_cost_float: isDividendPaying
                ? account.YieldatCost === 0
                  ? null
                  : account.YieldatCost
                : null,
            };
          }),
          "ticker"
        ),

        // Aggregated Data
        _ROW_TYPE: "POSITION_AGGREGATE",
        _ROW_IDENT: firstAccount.Ticker,
        average_yield_1yr: isDividendPaying
          ? toPercent(Yield_1yrAvg * 100)
          : null,
        average_yield_1yr_float: isDividendPaying ? Yield_1yrAvg * 100 : null,
        average_yield_3yr: isDividendPaying
          ? toPercent(Yield_3yrAvg * 100)
          : null,
        average_yield_3yr_float: isDividendPaying ? Yield_3yrAvg * 100 : null,
        average_yield_5yr: isDividendPaying
          ? toPercent(Yield_5yrAvg * 100)
          : null,
        average_yield_5yr_float: isDividendPaying ? Yield_5yrAvg * 100 : null,
        average_yield_10yr: isDividendPaying
          ? toPercent(Yield_10yrAvg * 100)
          : null,
        average_yield_10yr_float: isDividendPaying ? Yield_10yrAvg * 100 : null,
        average_yield_diff_1yr: isDividendPaying
          ? toPercent(estimatedYield - Yield_1yrAvg * 100)
          : null,
        average_yield_diff_1yr_float: isDividendPaying
          ? estimatedYield - Yield_1yrAvg * 100
          : null,
        average_yield_diff_3yr: isDividendPaying
          ? toPercent(estimatedYield - Yield_3yrAvg * 100)
          : null,
        average_yield_diff_3yr_float: isDividendPaying
          ? estimatedYield - Yield_3yrAvg * 100
          : null,
        average_yield_diff_5yr: isDividendPaying
          ? toPercent(estimatedYield - Yield_5yrAvg * 100)
          : null,
        average_yield_diff_5yr_float: isDividendPaying
          ? estimatedYield - Yield_5yrAvg * 100
          : null,
        average_yield_diff_10yr: isDividendPaying
          ? toPercent(estimatedYield - Yield_10yrAvg * 100)
          : null,
        average_yield_diff_10yr_float: isDividendPaying
          ? estimatedYield - Yield_10yrAvg * 100
          : null,
        beta: numberOrNull(parseFloat(firstAccount.Beta).toFixed(2)),
        cost: nullOnZero(totalCostBasis, toCurrency(totalCostBasis)),
        cost_float: totalCostBasis,
        cost_per_share: isCash
          ? null
          : nullOnZero(
              totalCostBasis / totalShares,
              toCurrency(totalCostBasis / totalShares)
            ),
        cost_per_share_float: totalCostBasis / totalShares,
        count_div_paid: firstAccount.CountDivPaid,
        day_change: isCash
          ? null
          : valueOrNull(dayChange, toCurrency(dayChange)),
        day_change_float: isCash ? null : dayChange,
        day_change_percent: justAdded
          ? toPercent(0)
          : zeroOnIsNan(percentDayChange, toPercent(percentDayChange)),
        day_change_percent_float: justAdded ? 0 : zeroOnIsNan(percentDayChange),
        div_growth_rate_alternate_1yr,
        div_growth_rate_alternate_yr_1yr,
        div_growth_rate_alternate_3yr,
        div_growth_rate_alternate_yr_3yr,
        div_growth_rate_alternate_5yr,
        div_growth_rate_alternate_yr_5yr,
        div_growth_rate_alternate_10yr,
        div_growth_rate_alternate_yr_10yr,
        div_growth_streak: isDividendPaying ? DivGrowthStreak : null,
        div_growth_velocity: isDividendPaying
          ? !isNull(DivGrowthVelocity)
            ? DivGrowthVelocity.toFixed(2)
            : null
          : null,
        div_non_reduced_streak: isDividendPaying ? DivNonReducedStreak : null,
        div_rate: isDividendPaying ? toCurrency(currentDivAmt) : null,
        div_rate_float: isDividendPaying ? currentDivAmt : null,
        div_rate_orig:
          isDividendPaying && currentDivAmtOrig
            ? toCurrency(currentDivAmtOrig, `${currentDivCurOrig} `)
            : null,
        div_rate_orig_float:
          isDividendPaying && currentDivAmtOrig ? currentDivAmtOrig : null,
        div_pay_streak: isDividendPaying ? DivPayoutStreak : null,
        div_status: isDividendPaying ? firstAccount.CurDivPayStatus : null,
        eps_ttm: toCurrency(firstAccount.EPS_TTM),
        eps_ttm_float: firstAccount.EPS_TTM,
        estimate_next_payment: toDate(firstAccount.CurrentDivPaidDate),
        estimate_yield: isDividendPaying
          ? valueOrNull(estimatedYield, toPercent(estimatedYield))
          : null,
        estimate_yield_float: estimatedYield,
        estimate_yr_income: isDividendPaying
          ? valueOrNull(
              totalEstimateYrIncome,
              toCurrency(totalEstimateYrIncome)
            )
          : null,
        estimate_yr_income_float: isDividendPaying
          ? valueOrNull(totalEstimateYrIncome)
          : null,
        estimate_yr_income_percent: isDividendPaying
          ? valueOrNull(
              totalEstimateYrIncome,
              toPercent((totalEstimateYrIncome / grandTotalEstYrIncome) * 100)
            )
          : null,
        estimate_yr_income_percent_float: isDividendPaying
          ? valueOrNull(
              totalEstimateYrIncome,
              (totalEstimateYrIncome / grandTotalEstYrIncome) * 100
            )
          : null,
        estimate_percent_income: isDividendPaying
          ? valueOrNull(
              totalEstimateYrIncome,
              toPercent((totalEstimateYrIncome / grandTotalEstYrIncome) * 100)
            )
          : null,
        estimate_percent_income_float: isDividendPaying
          ? valueOrNull(
              totalEstimateYrIncome,
              (totalEstimateYrIncome / grandTotalEstYrIncome) * 100
            )
          : null,
        ex_date: isDividendPaying
          ? toDate(firstAccount.CurrentDivExDate)
          : null,
        gain_loss: isCash
          ? null
          : valueOrNull(totalGainLoss, toCurrency(totalGainLoss)),
        gain_loss_float: isCash ? null : totalGainLoss,
        gain_loss_percent: isCash
          ? null
          : toPercent(
              totalCostBasis !== 0
                ? (totalGainLoss / Math.abs(totalCostBasis)) * 100
                : 0
            ),
        gain_loss_percent_float: isCash
          ? null
          : totalCostBasis !== 0
          ? (totalGainLoss / Math.abs(totalCostBasis)) * 100
          : 0,
        grand_total_market_value_float: grandTotalMarketValue,
        grand_total_estimate_yr_income_float: grandTotalEstYrIncome,
        growth_rate_1yr:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? toPercent(growthRate1Y)
            : null,
        growth_rate_1yr_converted_reason:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate1YConvertedReason
            : null,
        growth_rate_1yr_float:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate1Y
            : null,
        growth_rate_1yr_orig:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate1YOrig
            : null,
        growth_rate_3yr:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? toPercent(growthRate3Y)
            : null,
        growth_rate_3yr_converted_reason:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate3YConvertedReason
            : null,
        growth_rate_3yr_float:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate3Y
            : null,
        growth_rate_3yr_orig:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate3YOrig
            : null,
        growth_rate_5yr:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? toPercent(growthRate5Y)
            : null,
        growth_rate_5yr_converted_reason:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate5YConvertedReason
            : null,
        growth_rate_5yr_float:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate5Y
            : null,
        growth_rate_5yr_orig:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate5YOrig
            : null,
        growth_rate_10yr:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? toPercent(growthRate10Y)
            : null,
        growth_rate_10yr_converted_reason:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate10YConvertedReason
            : null,
        growth_rate_10yr_float:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate10Y
            : null,
        growth_rate_10yr_orig:
          isDividendPaying && firstAccount.Type !== "Preferred"
            ? growthRate10YOrig
            : null,
        has_note: hasNote || !isEmpty(resolveStockNote(position, stockNotes)),
        holding_type: firstAccount.Type,
        hl_percentage: valueOrNull((price - wk52Low) / (wk52High - wk52Low)),
        income_to_market_value_ratio: isDividendPaying
          ? toPercent(
              zeroOnIsNan(
                totalEstimateYrIncome /
                  grandTotalEstYrIncome /
                  (totalMarketValue / grandTotalMarketValue)
              )
            )
          : null,
        income_to_market_value_ratio_float: isDividendPaying
          ? zeroOnIsNan(
              totalEstimateYrIncome /
                grandTotalEstYrIncome /
                (totalMarketValue / grandTotalMarketValue)
            )
          : null,
        ipo_date: toDate(firstAccount.IPODate),
        is_dividend_paying: isDividendPaying,
        is_dormant: parseInt(totalShares) === 0 ? 1 : 0,
        is_foreign: isForeign,
        last_div_amount_float: isDividendPaying
          ? firstAccount.LastDivAmount
          : null,
        last_div_amount_orig_float: isDividendPaying
          ? firstAccount.LastOriginDivAmount
          : null,
        last_div_ex_date: isDividendPaying
          ? toDate(firstAccount.LastDivExDate)
          : null,
        market_cap:
          numberOrNull(marketCap) !== null ? `$${millify(marketCap)}` : null,
        market_cap_float: numberOrNull(marketCap) !== null ? marketCap : null,
        market_cap_type: firstAccount.MarketCapType,
        market_price: isCash ? null : valueOrNull(price, toCurrency(price)),
        market_price_float: isCash ? null : price,
        market_value: toCurrency(totalMarketValue),
        market_value_float: totalMarketValue,
        month_div_declarations: totalMonthDeclarations,
        month_div_estimates: totalMonthEstimates,
        month_div_payments: totalMonthPayments,
        non_income_impact: isDividendPaying
          ? null
          : toCurrency(totalMarketValue * portfolioYield),
        non_income_impact_float: isDividendPaying
          ? null
          : totalMarketValue * portfolioYield,
        news_ticker: firstAccount.mainticker || position.ticker,
        next_div_amount_float: isDividendPaying
          ? firstAccount.NextDivAmount
          : null,
        next_div_amount_orig_float: isDividendPaying
          ? firstAccount.NextOriginDivAmount
          : null,
        next_div_ex_date: isDividendPaying
          ? toDate(firstAccount.NextDivExDate)
          : null,
        name: startCase(String(firstAccount.TickerName).toUpperCase()),
        note_sort: !(
          hasNote || !isEmpty(resolveStockNote(position, stockNotes))
        ),
        notes: resolveStockNote(position, stockNotes),
        pay_frequency: isDividendPaying ? firstAccount.Frequency : null,
        payout_float: isDividendPaying ? totalShares * currentDivAmt : null,
        payout_ratio: isDividendPaying
          ? isNull(PayoutRatio)
            ? null
            : toPercent(PayoutRatio)
          : null,
        PayoutRatio: isDividendPaying
          ? isNull(PayoutRatio)
            ? null
            : toPercent(PayoutRatio)
          : null,
        payout_ratio_float: isDividendPaying ? PayoutRatio : null,
        percent_holdings: toPercent(
          (totalMarketValue / grandTotalMarketValueAll) * 100
        ),
        percent_holdings_float:
          (totalMarketValue / grandTotalMarketValueAll) * 100,
        portfolio_yield_float: portfolioYield,
        previous_close_float: previousClose,
        previous_market_value_float: totalPreviousMarketValue,
        price_change: isCash
          ? null
          : numberOrNull(priceChange, toCurrency(priceChange)),
        price_change_float: isCash ? null : numberOrNull(priceChange),
        projected_income_1yr_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome1YrCAGR_Reinvest,
        projected_income_3yr_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome3YrCAGR_Reinvest,
        projected_income_5yr_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome5YrCAGR_Reinvest,
        projected_income_10yr_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome10YrCAGR_Reinvest,
        projected_income_1yr_no_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome1YrCAGR_NoReinvest,
        projected_income_3yr_no_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome3YrCAGR_NoReinvest,
        projected_income_5yr_no_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome5YrCAGR_NoReinvest,
        projected_income_10yr_no_reinvest:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome10YrCAGR_NoReinvest,
        projected_income_1yr_current:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome1YrCAGR_Current,
        projected_income_3yr_current:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome3YrCAGR_Current,
        projected_income_5yr_current:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome5YrCAGR_Current,
        projected_income_10yr_current:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome10YrCAGR_Current,
        projected_income_1yr_contrib:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome1YrCAGR_Contrib,
        projected_income_3yr_contrib:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome3YrCAGR_Contrib,
        projected_income_5yr_contrib:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome5YrCAGR_Contrib,
        projected_income_10yr_contrib:
          parseInt(totalShares) === 0
            ? []
            : totalProjectedIncome10YrCAGR_Contrib,
        reliability_rating: ReliabilityRating,
        reliability_score: ReliabilityScore,
        sector: firstAccount.Sector || "TBD",
        sector_code: firstAccount.Sector || "TBD",
        shares: isCash
          ? null
          : valueOrNull(totalShares, toProperNumber(totalShares)),
        shares_float: isCash ? null : totalShares,
        shares_outstanding:
          numberOrNull(firstAccount.SharesOutstanding) !== null
            ? millify(parseFloat(firstAccount.SharesOutstanding) * 1000000, {
                space: true,
              })
            : null,
        shares_outstanding_float:
          numberOrNull(firstAccount.SharesOutstanding) !== null
            ? firstAccount.SharesOutstanding * 1000000
            : null,
        stock_note: resolveStockNote(position, stockNotes),
        superSector: firstAccount.SuperSector,
        super_sector: firstAccount.SuperSector,
        ticker: position.ticker,
        total_fees_ytd: isDividendPaying
          ? valueOrNull(totalFeesYtd, toCurrency(totalFeesYtd))
          : null,
        total_fees_ytd_float: totalFeesYtd,
        total_fees_ytd_percent: isDividendPaying
          ? valueOrNull(
              totalFeesYtd / grandTotalFeesYtd,
              toPercent((totalFeesYtd / grandTotalFeesYtd) * 100)
            )
          : null,
        total_fees_ytd_percent_float: valueOrNull(
          totalFeesYtd / grandTotalFeesYtd,
          (totalFeesYtd / grandTotalFeesYtd) * 100
        ),
        total_paid_ytd: isDividendPaying
          ? valueOrNull(totalPaidYtd, toCurrency(totalPaidYtd))
          : null,
        total_paid_ytd_float: isDividendPaying
          ? valueOrNull(totalPaidYtd)
          : null,
        total_paid_ytd_percent: isDividendPaying
          ? valueOrNull(
              totalPaidYtd / grandTotalPaidYtd,
              toPercent((totalPaidYtd / grandTotalPaidYtd) * 100)
            )
          : null,
        total_paid_ytd_percent_float: isDividendPaying
          ? valueOrNull(
              totalPaidYtd / grandTotalPaidYtd,
              (totalPaidYtd / grandTotalPaidYtd) * 100
            )
          : null,
        total_remaining: isDividendPaying
          ? valueOrNull(totalRemaining, toCurrency(totalRemaining))
          : null,
        total_remaining_float: isDividendPaying
          ? valueOrNull(totalRemaining)
          : null,
        total_remaining_percent: isDividendPaying
          ? valueOrNull(
              totalRemaining / grandTotalRemaining,
              toPercent((totalRemaining / grandTotalRemaining) * 100)
            )
          : null,
        total_remaining_percent_float: isDividendPaying
          ? valueOrNull(totalRemaining / grandTotalRemaining)
          : null,
        type_name: category,
        type_sort: getTypeSort(category, appSettings),
        upcoming_payments: firstAccount.UpcomingPayments,
        week_52_high: valueOrNull(wk52High, toCurrency(wk52High)),
        week_52_high_float: wk52High,
        week_52_low: valueOrNull(wk52Low, toCurrency(wk52Low)),
        week_52_low_float: wk52Low,
        yield_at_cost: isDividendPaying
          ? totalCostBasis === 0
            ? null
            : toPercent((yrDivRate / (totalCostBasis / totalShares)) * 100)
          : null,
        yield_at_cost_float: isDividendPaying
          ? totalCostBasis === 0
            ? null
            : numberOrNull((yrDivRate / (totalCostBasis / totalShares)) * 100)
          : null,
        yield_change_1yr:
          isDividendPaying && has1YrAvgYield
            ? toPercent(
                ((estimatedYield - Yield_1yrAvg * 100) / (Yield_1yrAvg * 100)) *
                  100
              )
            : null,
        yield_change_1yr_float:
          isDividendPaying && has1YrAvgYield
            ? ((estimatedYield - Yield_1yrAvg * 100) / (Yield_1yrAvg * 100)) *
              100
            : null,
        yield_change_3yr:
          isDividendPaying && has3YrAvgYield
            ? toPercent(
                ((estimatedYield - Yield_3yrAvg * 100) / (Yield_3yrAvg * 100)) *
                  100
              )
            : null,
        yield_change_3yr_float:
          isDividendPaying && has3YrAvgYield
            ? ((estimatedYield - Yield_3yrAvg * 100) / (Yield_3yrAvg * 100)) *
              100
            : null,
        yield_change_5yr:
          isDividendPaying && has5YrAvgYield
            ? toPercent(
                ((estimatedYield - Yield_5yrAvg * 100) / (Yield_5yrAvg * 100)) *
                  100
              )
            : null,
        yield_change_5yr_float:
          isDividendPaying && has5YrAvgYield
            ? ((estimatedYield - Yield_5yrAvg * 100) / (Yield_5yrAvg * 100)) *
              100
            : null,
        yield_change_10yr:
          isDividendPaying && has10YrAvgYield
            ? toPercent(
                ((estimatedYield - Yield_10yrAvg * 100) /
                  (Yield_10yrAvg * 100)) *
                  100
              )
            : null,
        yield_change_10yr_float:
          isDividendPaying && has10YrAvgYield
            ? ((estimatedYield - Yield_10yrAvg * 100) / (Yield_10yrAvg * 100)) *
              100
            : null,
        yr_div_rate: isDividendPaying
          ? valueOrNull(yrDivRate, toCurrency(yrDivRate, null, false))
          : null,
        yr_div_rate_float: yrDivRate,
      };
    });

    const display = sortBy(data, ["is_dormant", "type_sort", "ticker"]);

    window["_CACHED_DISPLAY"] = display;

    return display;
  }
};

export default processData;
