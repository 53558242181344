const handler = async (action, api) => {

  const { members, channelName, onComplete } = action.payload

  try {
    const response = await api.chat.createChannel({ members, channelName })
    onComplete && onComplete(response)
  } catch (error) {
    console.log(error)
  }

}

export { handler }