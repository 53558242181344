import { filter } from 'lodash'

const handler = (action, { stockNotes }) => {

  const newStockNotes = filter(stockNotes, stockNote => stockNote.ticker !== action.payload.ticker)

  return {
    stockNotes: newStockNotes
  }

}

export { handler }
