import {
  isEmpty,
  sortBy,
  groupBy,
  head,
  isNull,
  filter,
  includes,
  min,
  max,
  isNaN,
} from "lodash";

// import ArrowDropUpIcon from '@material-ui/icons/ArrowUpward'
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDownward'
// import ArrowNeutralIcon from '@material-ui/icons/Remove'

import moment from "moment";

import {
  abbreviate as abbr,
  toCurrency as toCur,
  toPercent as toPer,
  zeroOnIsNan as zeroOnNan,
  toProperNumber as toPropNum,
  toDate as toDt,
} from "./../state/selectors/manage/getProcessedData/helpers";

export function toProperNumber(num) {
  return toPropNum(num);
}

export function toDate(str, noYear = false) {
  return toDt(str, noYear);
}

export function abbreviate(str) {
  return abbr(str);
}

export function filterPositions({
  data,
  gridFilter,
  changeRange,
  yieldField,
  growthField,
  yieldMidpoint,
  growthMidpoint,
  month,
}) {
  let filteredData = [...data];

  if (!isNull(gridFilter)) {
    if (gridFilter.type === "tickers") {
      filteredData = filter(filteredData, ({ ticker }) =>
        includes(gridFilter.data.tickers, ticker)
      );
    }
    if (gridFilter.type === "sector") {
      filteredData = filter(
        filteredData,
        ({ sector_code }) => sector_code === gridFilter.data.sector
      );
    }
    if (gridFilter.type === "gain_loss") {
      if (changeRange === "overall") {
        if (gridFilter.data.type.toUpperCase() === "GAINERS") {
          filteredData = filter(
            filteredData,
            ({ gain_loss_float }) => gain_loss_float > 0
          );
        }
        if (gridFilter.data.type.toUpperCase() === "LOSERS") {
          filteredData = filter(
            filteredData,
            ({ gain_loss_float }) => gain_loss_float < 0
          );
        }
      } else {
        if (gridFilter.data.type.toUpperCase() === "GAINERS") {
          filteredData = filter(
            filteredData,
            ({ day_change_float }) => day_change_float > 0
          );
        }
        if (gridFilter.data.type.toUpperCase() === "LOSERS") {
          filteredData = filter(
            filteredData,
            ({ day_change_float }) => day_change_float < 0
          );
        }
      }
    }
    if (gridFilter.type === "dgv") {
      if (gridFilter.data.type.toUpperCase() === "DEC") {
        filteredData = filter(
          filteredData,
          ({ div_growth_velocity }) => div_growth_velocity < 1
        );
      }
      if (gridFilter.data.type.toUpperCase() === "ACC") {
        filteredData = filter(
          filteredData,
          ({ div_growth_velocity }) => div_growth_velocity >= 1
        );
      }
    }
    if (gridFilter.type === "income") {
      const tickers = [];
      gridFilter.data.records.forEach((record) => {
        if (!includes(tickers, record.Ticker)) {
          tickers.push(record.Ticker);
        }
      });
      filteredData = filter(filteredData, ({ ticker }) =>
        includes(tickers, ticker)
      );
    }
    if (gridFilter.type === "calendar_income") {
      filteredData = filter(filteredData, ({ ticker }) =>
        includes(gridFilter.data.tickers, ticker)
      );
    }
    if (gridFilter === "calendar_income") {
      filteredData = filter(filteredData, ({ ticker }) =>
        includes(gridFilter.data.tickers, ticker)
      );
    }
    if (gridFilter.type === "avg_yield_diff") {
      const tickers = [];
      gridFilter.data.positions.forEach((record) => {
        if (!includes(tickers, record.ticker)) {
          tickers.push(record.ticker);
        }
      });
      filteredData = filter(filteredData, ({ ticker }) =>
        includes(tickers, ticker)
      );
    }
    if (gridFilter.type === "yield_x_growth") {
      filteredData = filter(filteredData, (position) => {
        if (position.is_dividend_paying) {
          if (gridFilter.data.code === "hyhg") {
            return (
              position[yieldField] >= yieldMidpoint &&
              position[growthField] >= growthMidpoint
            );
          }
          if (gridFilter.data.code === "hylg") {
            return (
              position[yieldField] >= yieldMidpoint &&
              position[growthField] < growthMidpoint
            );
          }
          if (gridFilter.data.code === "lylg") {
            return (
              position[yieldField] < yieldMidpoint &&
              position[growthField] < growthMidpoint
            );
          }
          if (gridFilter.data.code === "lyhg") {
            return (
              position[yieldField] < yieldMidpoint &&
              position[growthField] >= growthMidpoint
            );
          }
        }
        return false;
      });
    }
    if (gridFilter.type === "predictability") {
      filteredData = filter(
        filteredData,
        ({ reliability_rating }) => reliability_rating === gridFilter.data.label
      );
    }
    if (gridFilter.type === "supersector") {
      filteredData = filter(
        filteredData,
        ({ superSector }) => superSector === gridFilter.data.code
      );
    }
    if (gridFilter.type === "date") {
      filteredData = filter(filteredData, ({ ticker }) =>
        gridFilter.data.tickers.includes(ticker)
      );
    }
  }
  // else if (month) {

  //   const monthMap = {
  //     '1': 'Jan', '2': 'Feb', '3': 'Mar', '4': 'Apr',
  //     '5': 'May', '6': 'Jun', '7': 'Jul', '8': 'Aug',
  //     '9': 'Sep', '10': 'Oct', '11': 'Nov', '12': 'Dec'
  //   }

  //   const positionData = []
  //   filteredData.forEach( position => {
  //     position.subRows.forEach(({dividend_history}) => {
  //       if (dividend_history) {
  //         dividend_history.forEach(thisHistory => {
  //           if (month === `${thisHistory.DivPayMonth}`) {
  //             positionData.push(position)
  //             return
  //           }
  //         })
  //       }
  //     })
  //   })
  //   return positionData
  // }

  return filteredData;
}

export function getTableTotal(field_float, rows, categorized = true) {
  let total = 0;
  if (categorized) {
    for (let category of rows) {
      total += category.subRows.reduce(
        (running_total, row) => zeroOnIsNan(row[field_float]) + running_total,
        0
      );
    }
  } else {
    total = rows.reduce(
      (running_total, row) => zeroOnIsNan(row[field_float]) + running_total,
      0
    );
  }

  return total;
}

export function getTableTotalGainLoss(rows, categorized = true) {
  let total = 0;
  if (categorized) {
    for (let category of rows) {
      total += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.gain_loss_float) + running_total,
        0
      );
    }
  } else {
    total = rows.reduce(
      (running_total, row) => zeroOnIsNan(row.gain_loss_float) + running_total,
      0
    );
  }

  return {
    value: total,
    percent:
      (total / Math.abs(getTableTotal("cost_float", rows, categorized))) * 100,
  };
}

export function getTableTotalDayChange(rows, categorized = true) {
  let total = 0;
  if (categorized) {
    for (let category of rows) {
      total += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.day_change_float) + running_total,
        0
      );
    }
  } else {
    total = rows.reduce(
      (running_total, row) => zeroOnIsNan(row.day_change_float) + running_total,
      0
    );
  }
  if (
    !isFinite(total) ||
    !isFinite(
      (total /
        Math.abs(
          getTableTotal("previous_market_value_float", rows, categorized)
        )) *
        100
    )
  ) {
    return { value: 0, percent: 0 };
  }
  return {
    value: total,
    percent:
      (total /
        Math.abs(
          getTableTotal("previous_market_value_float", rows, categorized)
        )) *
      100,
  };
}

export function getTableTotalEstimatedIncome(rows, categorized = true) {
  let value = 0;
  let percent = 0;
  if (categorized) {
    for (let category of rows) {
      value += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.estimate_yr_income_float) + running_total,
        0
      );
      percent += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.estimate_yr_income_percent_float) + running_total,
        0
      );
    }
  } else {
    value = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.estimate_yr_income_float) + running_total,
      0
    );
    percent = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.estimate_yr_income_percent_float) + running_total,
      0
    );
  }

  return { value, percent };
}

export function getTableTotalEstimatedRemaining(rows, categorized = true) {
  let value = 0;
  let percent = 0;
  if (categorized) {
    for (let category of rows) {
      value += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.total_remaining_float) + running_total,
        0
      );
      percent += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.total_remaining_percent_float) + running_total,
        0
      );
    }
  } else {
    value = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.total_remaining_float) + running_total,
      0
    );
    percent = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.total_remaining_percent_float) + running_total,
      0
    );
  }
  return { value, percent };
}

export function getTableTotalPaidYtd(rows, categorized = true) {
  let value = 0;
  let percent = 0;
  if (categorized) {
    for (let category of rows) {
      value += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.total_paid_ytd_float) + running_total,
        0
      );
      percent += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.total_paid_ytd_percent_float) + running_total,
        0
      );
    }
  } else {
    value = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.total_paid_ytd_float) + running_total,
      0
    );
    percent = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.total_paid_ytd_percent_float) + running_total,
      0
    );
  }
  return { value, percent };
}

export function getTableTotalFeesYtd(rows, categorized = true) {
  let value = 0;
  let percent = 0;
  if (categorized) {
    for (let category of rows) {
      value += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.total_fees_ytd_float) + running_total,
        0
      );
      percent += category.subRows.reduce(
        (running_total, row) =>
          zeroOnIsNan(row.total_fees_ytd_percent_float) + running_total,
        0
      );
    }
  } else {
    value = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.total_fees_ytd_float) + running_total,
      0
    );
    percent = rows.reduce(
      (running_total, row) =>
        zeroOnIsNan(row.total_fees_ytd_percent_float) + running_total,
      0
    );
  }
  return { value, percent };
}

export function getTickerSelections(current, selected, arrayMode) {
  if (!arrayMode) {
    if (head(current) === selected) {
      return null;
    } else {
      return [selected];
    }
  } else {
    if (!includes(current, selected)) {
      return [...current, selected].sort();
    } else {
      const newSet = filter(current, (item) => item !== selected);
      return isEmpty(newSet) ? null : newSet.sort();
    }
  }
}

export function groupPositions(
  positions,
  {
    gainLossMode,
    dayChangeMode,
    estYrIncMode,
    totPdYtdMode,
    totRemIncMode,
    totFeesYtdMode,
  }
) {
  const keyedGroup = groupBy(positions, "type_sort");
  const grouped = [];

  let categoryCounter = 0;

  for (let key in keyedGroup) {
    const rows = keyedGroup[key];

    const gainLoss = getTableTotalGainLoss(rows, false);
    const dayChange = getTableTotalDayChange(rows, false);

    const yrIncome = getTableTotalEstimatedIncome(rows, false);
    const pdYtd = getTableTotalPaidYtd(rows, false);
    const remYtd = getTableTotalEstimatedRemaining(rows, false);
    const feesYtd = getTableTotalFeesYtd(rows, false);

    const totalMarketValue = getTableTotal("market_value_float", rows, false);
    const totalMonthDivEstimate = getTableTotal(
      "month_div_estimate_float",
      rows,
      false
    );
    const totalMonthDivPayment = getTableTotal(
      "month_div_payment_float",
      rows,
      false
    );

    const totalIncome = getTableTotal("estimate_yr_income_float", rows, false);

    const totalProjectedIncomeDgr = getTableTotal(
      "projected_income_dgr_float",
      rows,
      false
    );
    const totalGrowthAmountDgr = getTableTotal(
      "growth_amount_dgr_float",
      rows,
      false
    );

    const totalProjectedIncomeDgrRi = getTableTotal(
      "projected_income_dgr_ri_float",
      rows,
      false
    );
    const totalGrowthAmountDgrRi = getTableTotal(
      "growth_amount_dgr_ri_float",
      rows,
      false
    );

    const totalProjectedIncomeDgrRiContrib = getTableTotal(
      "projected_income_dgr_ri_contrib_float",
      rows,
      false
    );
    const totalGrowthAmountDgrRiContrib = getTableTotal(
      "growth_amount_dgr_ri_contrib_float",
      rows,
      false
    );

    let dataRow = {
      _ROW_TYPE: "CATEGORY",
      _ROW_IDENT: head(rows).type_name,
      _ROW_TABLE_ID: String(categoryCounter),
      cost: toCurrency(getTableTotal("cost_float", rows, false)),
      cost_float: getTableTotal("cost_float", rows, false),
      ticker: <span className="font-bold">{head(rows).type_name}</span>,
      growth_amount_dgr: toGainLossFormat(
        totalGrowthAmountDgr,
        toCurrency(totalGrowthAmountDgr),
        true
      ),
      growth_amount_dgr_ri: toGainLossFormat(
        totalGrowthAmountDgrRi,
        toCurrency(totalGrowthAmountDgrRi),
        true
      ),
      growth_amount_dgr_ri_contrib: toGainLossFormat(
        totalGrowthAmountDgrRiContrib,
        toCurrency(totalGrowthAmountDgrRiContrib),
        true
      ),
      estimate_percent_income: toPercent(
        (totalIncome / head(rows).grand_total_estimate_yr_income_float) * 100
      ),
      estimate_percent_income_float:
        (totalIncome / head(rows).grand_total_estimate_yr_income_float) * 100,
      estimate_yr_income:
        estYrIncMode === "%"
          ? toPercent(yrIncome.percent)
          : toCurrency(yrIncome.value),
      total_paid_ytd:
        totPdYtdMode === "%"
          ? toPercent(pdYtd.percent)
          : toCurrency(pdYtd.value),
      total_fees_ytd:
        totFeesYtdMode === "%"
          ? toPercent(feesYtd.percent)
          : toCurrency(feesYtd.value),
      total_remaining:
        totRemIncMode === "%"
          ? toPercent(remYtd.percent)
          : toCurrency(remYtd.value),
      market_value: toCurrency(totalMarketValue),
      market_value_float: totalMarketValue,
      month_div_estimate: toCurrency(totalMonthDivEstimate),
      month_div_estimate_float: totalMonthDivEstimate,
      month_div_payment: toCurrency(totalMonthDivPayment),
      month_div_payment_float: totalMonthDivPayment,
      percent_holdings: toPercent(
        (totalMarketValue / head(rows).grand_total_market_value_float) * 100
      ),
      percent_holdings_float:
        (totalMarketValue / head(rows).grand_total_market_value_float) * 100,
      projected_income_dgr: toCurrency(totalProjectedIncomeDgr),
      projected_income_dgr_ri: toCurrency(totalProjectedIncomeDgrRi),
      projected_income_dgr_ri_contrib: toCurrency(
        totalProjectedIncomeDgrRiContrib
      ),
    };

    let aggregates = {
      _ROW_TYPE: "AGGREGATE",
      cost: (
        <div className="font-bold">
          {toCurrency(getTableTotal("cost_float", rows, false))}
        </div>
      ),
      ticker: (
        <div className="text-right font-bold text-sm">
          {head(rows).type_name} Total
        </div>
      ),
      estimate_yr_income: (
        <div className="font-bold">
          {estYrIncMode === "%"
            ? toPercent(yrIncome.percent)
            : toCurrency(yrIncome.value)}
        </div>
      ),
      growth_amount_dgr: (
        <div className="font-bold">
          {toGainLossFormat(
            totalGrowthAmountDgr,
            toCurrency(totalGrowthAmountDgr),
            true
          )}
        </div>
      ),
      growth_amount_dgr_ri: (
        <div className="font-bold">
          {toGainLossFormat(
            totalGrowthAmountDgrRi,
            toCurrency(totalGrowthAmountDgrRi),
            true
          )}
        </div>
      ),
      growth_amount_dgr_ri_contrib: (
        <div className="font-bold">
          {toGainLossFormat(
            totalGrowthAmountDgrRiContrib,
            toCurrency(totalGrowthAmountDgrRiContrib),
            true
          )}
        </div>
      ),
      total_paid_ytd: (
        <div className="font-bold">
          {totPdYtdMode === "%"
            ? toPercent(pdYtd.percent)
            : toCurrency(pdYtd.value)}
        </div>
      ),
      total_fees_ytd: (
        <div className="font-bold">
          {totFeesYtdMode === "%"
            ? toPercent(feesYtd.percent)
            : toCurrency(feesYtd.value)}
        </div>
      ),
      total_remaining: (
        <div className="font-bold">
          {totRemIncMode === "%"
            ? toPercent(remYtd.percent)
            : toCurrency(remYtd.value)}
        </div>
      ),
      market_value: (
        <div className="font-bold">{toCurrency(totalMarketValue)}</div>
      ),
      month_div_payment: (
        <div className="font-bold">{toCurrency(totalMonthDivPayment)}</div>
      ),
      projected_income_dgr: (
        <div className="font-bold">{toCurrency(totalProjectedIncomeDgr)}</div>
      ),
      projected_income_dgr_ri: (
        <div className="font-bold">{toCurrency(totalProjectedIncomeDgrRi)}</div>
      ),
      projected_income_dgr_ri_contrib: (
        <div className="font-bold">
          {toCurrency(totalProjectedIncomeDgrRiContrib)}
        </div>
      ),
    };

    if (
      !isNull(head(rows).sector_code) &&
      head(rows).sector_code.trim() !== "CASH"
    ) {
      dataRow = {
        ...dataRow,
        day_change:
          dayChangeMode === "%"
            ? toGainLossFormat(
                dayChange.percent,
                toPercent(dayChange.percent),
                true
              )
            : toGainLossFormat(
                dayChange.value,
                toCurrency(dayChange.value),
                true
              ),
        gain_loss:
          gainLossMode === "%"
            ? toGainLossFormat(
                gainLoss.percent,
                toPercent(gainLoss.percent),
                true
              )
            : toGainLossFormat(
                gainLoss.value,
                toCurrency(gainLoss.value),
                true
              ),
      };

      aggregates = {
        ...aggregates,
        day_change: (
          <div className="font-bold">
            {dayChangeMode === "%"
              ? toGainLossFormat(
                  dayChange.percent,
                  toPercent(dayChange.percent),
                  true
                )
              : toGainLossFormat(
                  dayChange.value,
                  toCurrency(dayChange.value),
                  true
                )}
          </div>
        ),
        gain_loss: (
          <div className="font-bold">
            {gainLossMode === "%"
              ? toGainLossFormat(
                  gainLoss.percent,
                  toPercent(gainLoss.percent),
                  true
                )
              : toGainLossFormat(
                  gainLoss.value,
                  toCurrency(gainLoss.value),
                  true
                )}
          </div>
        ),
      };
    }

    const subRows = [];
    let index = 0;
    for (let position of keyedGroup[key]) {
      position.subRows = position.subRows.map((subRow, index) => ({
        ...subRow,
        index,
      }));
      subRows.push({
        ...position,
        _ROW_TABLE_ID: `${categoryCounter}.${index++}`,
      });
    }

    dataRow.subRows = [...subRows, aggregates];

    categoryCounter++;

    grouped.push(dataRow);
  }

  return grouped;
}

export function toCurrency(
  value,
  symbol = "$",
  intOnly = false,
  precision = 2
) {
  return toCur(value, symbol, intOnly, precision);
}

export function toGainLossFormat(
  val,
  display,
  addPlus = false,
  green,
  red,
  baseNumber = 0
) {
  if (val === null) {
    return null;
  }

  const gainColor = green || "text-green-400 dark:text-green-500";
  const lossColor = red || "text-red-400 dark:text-red-500";
  const neutralColor = "text-brand-font-base dark:text-white";

  if (isNaN(val)) {
    return <div className={neutralColor}>0%</div>;
  }

  let className = "flex items-center";
  if (val === baseNumber) {
    className = neutralColor;
  } else {
    if (val > baseNumber) {
      className = gainColor;
    } else {
      className = lossColor;
    }
  }

  if (addPlus) {
    if (val > baseNumber) {
      return (
        <div className={className}>
          +{isEmpty(display) ? val : display}
          {/* <ArrowDropUpIcon fontSize="inherit" /> */}
        </div>
      );
    } else if (val < baseNumber) {
      return (
        <div className={className}>
          {isEmpty(display) ? val : display}
          {/* <ArrowDropDownIcon fontSize="inherit" /> */}
        </div>
      );
    } else {
      return (
        <div className={className}>
          {isEmpty(display) ? val : display}
          {/* <ArrowNeutralIcon fontSize="inherit" /> */}
        </div>
      );
    }
  } else {
    return <div className={className}>{isEmpty(display) ? val : display}</div>;
  }
}

export function toPercent(num) {
  return toPer(num);
}

export function getSortingValue(toSet, current) {
  if (!isEmpty(current)) {
    const [field, sort] = current.split(":", 2);
    if (toSet === field) {
      if (sort === "asc") {
        return `${toSet}:desc`;
      } else {
        return `${toSet}:asc`;
      }
    } else {
      return `${toSet}:asc`;
    }
  } else {
    return `${toSet}:asc`;
  }
}

export function getUnixTimestamp() {
  return Math.round(new Date().getTime() / 1000);
}

export function getUrlParam(key) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key);
}

export function preProcessLabel(label, { gridFilter }) {
  let newLabel = label;

  if (!isEmpty(label)) {
    let SELECTED_MONTH = moment().format("MMM");

    if (!isNull(gridFilter)) {
      if (gridFilter.type === "income") {
        SELECTED_MONTH = gridFilter.data.month;
      } else if (gridFilter.type === "calendar_income") {
        SELECTED_MONTH = gridFilter.data.month;
      } else if (gridFilter.type === "date") {
        SELECTED_MONTH = gridFilter.data.month;
      }
    }

    const CHANGE_RANGE = isEmpty(getUrlParam("changeRange")) ? "Day" : "Total";
    const AVERAGE_YIELD_YEAR = isEmpty(getUrlParam("avgYieldMode"))
      ? "3"
      : getUrlParam("avgYieldMode");
    const SELECTED_YEAR = isEmpty(getUrlParam("projectedYear"))
      ? parseInt(moment().format("YYYY")) + 10
      : getUrlParam("projectedYear");

    newLabel = newLabel.replace("$SELECTED_MONTH", SELECTED_MONTH);
    newLabel = newLabel.replace("$SELECTED_YEAR", SELECTED_YEAR);
    newLabel = newLabel.replace("$CHANGE_RANGE", CHANGE_RANGE);
    newLabel = newLabel.replace("$AVERAGE_YIELD_YEAR", AVERAGE_YIELD_YEAR);
  }

  return newLabel;
}

export function setUrlParam(key, value) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    if (value === null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    const newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

export function sortPositions(data, sortField = "ticker:asc") {
  const grouping = "type_sort";
  const sorting = ["is_dormant", "null_sort", "type_sort"];

  if (!isEmpty(sortField)) {
    const [field, direction] = sortField.split(":", 2);

    data = data.map((row) => ({
      ...row,
      null_sort: row[field] === null ? 1 : 0,
    }));

    if (direction === "desc") {
      data = data.map((row) => ({
        ...row,
        is_dormant: row.is_dormant === 1 ? 0 : 1,
        null_sort: row.null_sort === 1 ? 0 : 1,
      }));
    }

    data = sortBy(data, [...sorting, field]);

    if (direction === "desc") {
      let reversedData = [];
      const grouped = groupBy(data, grouping);
      for (let index in grouped) {
        const group = grouped[index];
        reversedData = [...reversedData, ...group.reverse()];
      }
      data = reversedData;
    }
  }

  return data;
}

export function zeroOnIsNan(val) {
  return zeroOnNan(val);
}

export function getStockColor(
  position,
  identifierPercent,
  range,
  changeRanges,
  isDark = false
) {
  const value = position[identifierPercent];
  const type = value < 0 ? "loss" : "gain";
  const buckets = filter(changeRanges, { Type: `${range}-${type}` });
  const colorField = isDark ? "DarkColor" : "Color";

  let color = "#cccccc";

  for (let bucket of buckets) {
    if (isNull(bucket.FromValue)) {
      if (value <= bucket.ToValue) {
        color = bucket[colorField];
      }
    } else if (isNull(bucket.ToValue)) {
      if (value >= bucket.FromValue) {
        color = bucket[colorField];
      }
    } else {
      if (value >= bucket.FromValue && value <= bucket.ToValue) {
        color = bucket[colorField];
      }
    }
  }

  return color;
}

export function getStockTextColor(
  position,
  identifierPercent,
  range,
  changeRanges,
  isDark = false
) {
  const value = position[identifierPercent];
  const type = value < 0 ? "loss" : "gain";
  const buckets = filter(changeRanges, { Type: `${range}-${type}` });
  const colorField = isDark ? "DarkTextColor" : "TextColor";

  let color = "#cccccc";

  for (let bucket of buckets) {
    if (isNull(bucket.FromValue)) {
      if (value <= bucket.ToValue) {
        color = bucket[colorField];
      }
    } else if (isNull(bucket.ToValue)) {
      if (value >= bucket.FromValue) {
        color = bucket[colorField];
      }
    } else {
      if (value >= bucket.FromValue && value <= bucket.ToValue) {
        color = bucket[colorField];
      }
    }
  }

  return color;
}

export function getStockSize(position, data) {
  const percentages = [];

  data.forEach((record) => {
    const recordPercentage = zeroOnIsNan(record.percent_holdings_float);
    percentages.push(Math.abs(recordPercentage));
  });

  const relativePercentage =
    ((Math.abs(position.percent_holdings_float) - min(percentages)) /
      (max(percentages) - min(percentages))) *
    70;

  const stopSize = 15;

  let nearestStop = Math.ceil((relativePercentage || 1) / stopSize) * stopSize;

  if (nearestStop === 0) {
    nearestStop = stopSize;
  }

  return nearestStop;
}

export function nFormat(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function getProfilePhotoUrl(user, refresh = true) {
  return `https://d360static.blob.core.windows.net/dp-static/${user.userId.toUpperCase()}.jpg${
    refresh ? `?v=${Math.floor(Date.now() / 1000)}` : ""
  }`;
}
