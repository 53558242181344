const handler = async (action, api) => {
  try {
    const {
      payload: { priceid, success_url, onComplete },
    } = action;
    const response = await api.billing.getSubcriptionCheckoutSession({
      priceid,
      success_url,
    });
    onComplete && onComplete(response);
  } catch (error) {
    //console.log("may errror", error)
  }
};
export { handler };
