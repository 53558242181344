import resolveMarketValue from "./resolveMarketValue";
import moment from "moment";

const resolveGainLoss = (account, currentMetrics) => {
  let gainLoss = null;

  if (
    account.IsInWatchlist &&
    moment().startOf("day").isSame(moment(account.UPCreatedAt).startOf("day"))
  ) {
    gainLoss = 0;
  } else {
    const marketValue = resolveMarketValue(account, currentMetrics);
    gainLoss = marketValue - account.CostBasis;
    if (account.isShort) {
      gainLoss = marketValue + account.CostBasis;
    }
  }

  return gainLoss;
};

export default resolveGainLoss;
