import { isNull, find, isEmpty, head, filter, includes } from 'lodash'

const handler = action => {

  const state = action.payload.state

  let positions = null
  if (!isNull(state.account.positions)) {
    positions = filter(state.account.positions.map(position => ({
      ...position,
      accounts: filter(position.accounts, ({ userpositionid }) => !includes(state.plan.deletedPositions, userpositionid))
    })), position => !isEmpty(position.accounts))
  }
  let dividendHistory = state.account.dividendHistory
  let incomeProjection = state.account.incomeProjection
  let transactions = state.account.transactions

  if (!isNull(positions)) {

    if (!isNull(transactions)) {

      positions = positions.map(position => {
  
        const newPosition = { ...position }
  
        const newAccounts = newPosition.accounts.map(account => {
  
          const newAccount = { ...account }

          const transactionPosition = find(transactions, { ticker: account.Ticker })
          if (!isEmpty(transactionPosition)) {
            for (let transactionAccount of transactionPosition.accounts) {
              if (!isEmpty(transactionAccount.Trades)) {
                if (head(transactionAccount.Trades).userAccountId === newAccount.useraccountid) {
                  newAccount.Trades = transactionAccount.Trades
                }
              }
              if (!isEmpty(transactionAccount.DividendPayments)) {
                if (head(transactionAccount.DividendPayments).userAccountId === newAccount.useraccountid) {
                  newAccount.DividendPayments = transactionAccount.DividendPayments
                }
              }
            }
          }
  
          return newAccount
  
        })
  
        return {
          ...position,
          accounts: newAccounts
        }
  
      })
  
    }

    if (!isNull(dividendHistory)) {

      positions = positions.map(position => {
  
        const newPosition = { ...position }
  
        const newAccounts = newPosition.accounts.map(account => {
  
          const newAccount = { ...account }

          const historyPosition = find(dividendHistory, { ticker: account.Ticker })
          if (!isEmpty(historyPosition)) {
            for (let historyAccount of historyPosition.accounts) {
              if (head(historyAccount.DivHistory).useraccountid === newAccount.useraccountid) {
                newAccount.DivHistory = historyAccount.DivHistory
              } 
            }
          }
  
          return newAccount
  
        })
  
        return {
          ...position,
          accounts: newAccounts
        }
  
      })
  
    }

    if (!isNull(incomeProjection)) {

      positions = positions.map(position => {
  
        const newPosition = { ...position }
  
        const newAccounts = newPosition.accounts.map(account => {
  
          const newAccount = { ...account }
  
          const projectionPosition = find(incomeProjection, { ticker: account.Ticker })
          if (!isEmpty(projectionPosition)) {
            for (let projectionAccount of projectionPosition.accounts) {
  
              if ('1YR' in projectionAccount.IncomeProjection) {
                if (head(projectionAccount.IncomeProjection['1YR']).userAccountId === newAccount.useraccountid) {
                  newAccount.IncomeProjection = projectionAccount.IncomeProjection
                }
              } else if ('3YR' in projectionAccount.IncomeProjection) {
                if (head(projectionAccount.IncomeProjection['3YR']).userAccountId === newAccount.useraccountid) {
                  newAccount.IncomeProjection = projectionAccount.IncomeProjection
                }
              } else if ('5YR' in projectionAccount.IncomeProjection) {
                if (head(projectionAccount.IncomeProjection['5YR']).userAccountId === newAccount.useraccountid) {
                  newAccount.IncomeProjection = projectionAccount.IncomeProjection
                }
              } else if ('10YR' in projectionAccount.IncomeProjection) {
                if (head(projectionAccount.IncomeProjection['10YR']).userAccountId === newAccount.useraccountid) {
                  newAccount.IncomeProjection = projectionAccount.IncomeProjection
                }
              }
            }
          }
  
          return newAccount
  
        })

        return {
          ...position,
          accounts: newAccounts
        }
  
      })

    }

  }

  return { positions }


}

export { handler }
  