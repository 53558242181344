// TODO: Decouple RTS in its own service

import config from './../../../../config'
import axios from 'axios'

import { addToPubSubSubscription } from './../../../actions/user'

import { includes } from 'lodash'

const handler = async (action, _api, dispatch, getState) => {

  const state = getState()

  const pubSubSubscriptions = state.user.pubSubSubscriptions

  if (!includes(pubSubSubscriptions, 'default')) {

    const socket_id = action.payload.socketId
    const rooms = action.payload.pubSubHubs

    await axios.post(
      `${config.SOCKET_SERVER_URL}/join`,
      JSON.stringify({ socket_id, rooms }),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )

    dispatch(addToPubSubSubscription({ type: 'default' }))

  }

  

}

export { handler }