import { filter } from 'lodash'

const handler = (action, { positionNotes }) => {

  const newPositionNotes = filter(positionNotes, positionNote => positionNote.userPositionId !== action.payload.id)

  return {
    positionNotes: newPositionNotes
  }

}

export { handler }
