const handler = async (action, api) => {
  const {
    payload: { key, value, callback, forceCallback = false },
  } = action;
  try {
    await api.user.setUserConfig({ key, value });
    await callback();
  } catch {
    if (forceCallback) {
      await callback();
    }
  }
};

export { handler };
