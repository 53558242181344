const handler = (action, state) => {

  const selectedModule = state.selectedModule

  if (selectedModule === 'manage') {
    return { gridFilter: action.payload.gridFilter }
  } else {
    return { gridFilterPlan: action.payload.gridFilter }
  }

}

export { handler }