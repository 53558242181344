const handler = (action, { pendingLinks }) => {

  const { providerAccountId, key, value } = action.payload

  const newPendingLinks = pendingLinks.map(link => {
    if (link.providerAccountId === providerAccountId) {
      return {
        ...link,
        [key]: value
      }
    } else {
      return link
    }
  })

  return {
    pendingLinks: newPendingLinks
  }
  
}

export { handler }
