import getUserConfig from "./global/getUserConfig";
import getAppSettings from "./global/getAppSettings";
import { isNull } from "lodash";

export const getBlockingMessage = (state) => state.user.blockingMessage;

export const getIsAuthenticated = (state) => state.user.isAuthenticated;

export const getIsCompact = (state) => getUserConfig(state, "compact");
export const getIsDark = (state) => getUserConfig(state, "dark");
export const getTncVersion = (state) => getUserConfig(state, "tnc_version");
export const getLatestTncVersion = (state) =>
  getAppSettings(state, "tnc_version");
export const getIsPortfolioUpdating = (state) =>
  getUserConfig(state, "portfolio_updating");
export const getSandboxMode = (state) =>
  isNull(getUserConfig(state, "sandbox_mode"))
    ? false
    : getUserConfig(state, "sandbox_mode");
export const getSandboxModeRaw = (state) =>
  getUserConfig(state, "sandbox_mode");
export const getHideToolTips = (state) => getUserConfig(state, "hide_tooltips");
export const getHideChartToolTips = (state) =>
  getUserConfig(state, "hide_chart_tooltips");
export const getHasExpandedChartData_MarketReturn = (state) =>
  getUserConfig(state, "has_expanded_chart_data_market_return");
export const getHasExpandedChartData_DGR = (state) =>
  getUserConfig(state, "has_expanded_chart_data_market_dgr");
export const getHasExpandedChartData_DGV = (state) =>
  getUserConfig(state, "has_expanded_chart_data_market_dgv");
export const getHasExpandedChartData_IncomeYield = (state) =>
  getUserConfig(state, "has_expanded_chart_data_income_yield");
export const getPerformanceChangeMode = (state) =>
  getUserConfig(state, "performance_change_mode");
export const getAllocationChangeMode = (state) =>
  getUserConfig(state, "allocation_change_mode");
export const getFigiRatingChangeMode = (state) =>
  getUserConfig(state, "figi_rating_change_mode");
export const getIsCompactKPI = (state) => getUserConfig(state, "compact_kpi");
export const getIsAllocationsIncome = (state) =>
  getUserConfig(state, "compact_allocations_chart_mode");
export const getIsFigiAllocations = (state) =>
  getUserConfig(state, "compact_figi_chart_mode");

export const getHasUploadedPhoto = (state) =>
  getUserConfig(state, "has_uploaded_photo");
export const getScreenerSavePath = (state) =>
  getUserConfig(state, "screener_save_path");

export const getFeatures = (state) => getUserConfig(state, "features");

export const getAuthedUser = (state) => state.user.authedUser;

export const getACLPolicies = (state) => state?.user?.config?.acl_policies;
export const getNotificationMessage = (state) => state.user.notificationMessage;

export const getPubSubHubs = (state) =>
  state.user.config ? state.user.config.pubsub_hubs : null;
export const getSocket = (state) => state.user.socket;
export const getIsSocketConnected = (state) => state.user.isSocketConnected;

export const getPubSubSubscriptions = (state) => state.user.pubSubSubscriptions;

export const getStringLibrary = (state) =>
  getAppSettings(state, "string_library");

export const getURLQuery = (state) => getUserConfig(state, "query");
