import { setConfig } from  './../../../actions/user'

import {
  setSelectedAccounts,
  setSelectedWatchlists,
  setSelectedSectors,
  setSelectedPositionType,
  setDisplaySoldPositions
} from './../../../actions/manage'

// import {
//   setMarketStatus
// } from './../../../actions/market'

import { isEmpty } from 'lodash'

const handler = async (_action, api, dispatch) => {

  const response = await api.user.getUserConfig()

  // dispatch(setMarketStatus(response.config.market_status))

  dispatch(setConfig(response.config))

  if ('selectedAccounts' in response.config.user_config) {
    if (isEmpty(response.config.user_config.selectedAccounts)) {
      dispatch(setSelectedAccounts(null))
    } else {
      dispatch(setSelectedAccounts(response.config.user_config.selectedAccounts))
    }
  }

  if ('selectedWatchlists' in response.config.user_config) {
    if (isEmpty(response.config.user_config.selectedWatchlists)) {
      dispatch(setSelectedWatchlists({selectedWatchlists:[]}))
    } else {
      dispatch(setSelectedWatchlists({selectedWatchlists: response.config.user_config.selectedWatchlists}))
    }
  }

  if ('selectedSectors' in response.config.user_config) {
    if (isEmpty(response.config.user_config.selectedSectors)) {
      dispatch(setSelectedSectors(null))
    } else {
      dispatch(setSelectedSectors(response.config.user_config.selectedSectors))
    }
  }

  if ('selectedPositionType' in response.config.user_config) {
    if (isEmpty(response.config.user_config.selectedPositionType)) {
      dispatch(setSelectedPositionType(null))
    } else {
      dispatch(setSelectedPositionType(response.config.user_config.selectedPositionType))
    }
  }

  if ('displaySoldPositions' in response.config.user_config) {
    dispatch(setDisplaySoldPositions(response.config.user_config.displaySoldPositions))
  }

}

export { handler }
