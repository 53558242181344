import currencyFormatter from 'currency-formatter'
import abbr from 'abbreviate'

import {
  isInteger,
  isNull,
  isUndefined,
  isFinite
} from 'lodash'

export function abbreviate(str) {
  return abbr(str.toUpperCase(), { length: 2 })
}

export function nullOnZero(value, display) {
  if (isNull(value)) {
    return null
  }
  return (parseFloat(value) === 0) ? null : display
}

export function numberOrNull(value, display) {
  return isNaN(value) ? null : isUndefined(display) ? value : display
}

export function toDate(str, noYear = false) {
  try {
    const dateParts = str.split('T')[0].split('-')
    return noYear ? `${dateParts[1]}/${dateParts[2]}` : `${dateParts[1]}/${dateParts[2]}/${dateParts[0].slice(-2)}`

  } catch (_err) {
    return null
  }
} 

export function toCurrency(value, symbol = '$', intOnly = false, precision = 2) {
  if (intOnly) {
    return currencyFormatter.format(Math.round(value), { symbol, precision: 0 })
  }
  return currencyFormatter.format(value, { precision, symbol })
}

export function toPercent(num){
  return `${(Math.round((num + Number.EPSILON) * 100) / 100).toFixed(2)}%`
}

export function toProperNumber(num) {
  return toCurrency(num, '', isInteger(num))
}

export function valueOrNull(value, display) {
  return isNull(value) || isNaN(value) || !isFinite(value) ? null : isUndefined(display) ? value : display
}

export function zeroOnIsNan(val, display) {
  return (isNaN(val) || isNull(val) || !isFinite(val) || val === undefined) ? 0 : isUndefined(display) ? val : display
}