import { filter } from 'lodash'

const handler = (action, { newNotifications }) => {

  return {
    newNotifications: filter(newNotifications, ({ userNotificationId }) => userNotificationId !== action.payload.id)
  }

}

export { handler }