import { find } from 'lodash'

const handler = (action, { marketIndeces }) => {

  const newIndeces = [ ...action.payload.marketIndeces ]

  for (let index of marketIndeces) {
    const found = find(newIndeces, { ticker: index.ticker })
    if (!found) {
      newIndeces.push(index)
    }
  }

  return {
    marketIndeces: newIndeces
  }

}

export { handler }