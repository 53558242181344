import { uniq, isNull } from 'lodash'

const handler = action => {

  return {
    selectedWatchlists:
      isNull(action.payload.selectedWatchlists) ? null :
      uniq(action.payload.selectedWatchlists)
  }

}

export { handler }
