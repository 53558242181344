import { setFinancials } from './../../../actions/security'

const handler = async (action, api, dispatch) => {

  const { ticker, onComplete } = action.payload

  if (!onComplete) {
    dispatch(setFinancials(null))
  }
  
  const financials = await api.security.getFinancials({ ticker })

  if (onComplete) {
    onComplete(financials)
  } else {
    dispatch(setFinancials(financials))
  }
  
}

export { handler }