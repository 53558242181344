import {
  isNull,
  //filter,
  head,
  isNaN,
} from "lodash";
import getPerformanceTotals from "./getPerformanceTotals";

import resolveYield from "./resolveYield";
import resolveMarketValue from "./resolveMarketValue";

const getYieldTotals = (positions, currentMetrics) => {
  let totalYieldAtCost = 0;
  let totalCurrentYield = 0;

  if (!isNull(positions)) {
    // const filteredPositions = filter(positions, position => head(position.accounts).divstatus === 'Dividend')
    const filteredPositions = positions;

    const { totalMarketValue } = getPerformanceTotals(
      filteredPositions,
      currentMetrics
    );

    filteredPositions.forEach((position) => {
      const estCurrentYield = resolveYield(position, currentMetrics) || 0;

      let totalCostBasis = 0;
      let totalShares = 0;
      const yrDivRate = head(position.accounts).EstAnnualDiv || 0;

      let positionYieldAtCost = 0;
      let positionMarketValue = 0;

      position.accounts.forEach((account) => {
        const accountMarketValue = resolveMarketValue(account, currentMetrics);
        const percentOfHoldings = accountMarketValue / totalMarketValue;
        const weightedAvgCurrentYield = estCurrentYield * percentOfHoldings;

        positionMarketValue += accountMarketValue;
        totalCurrentYield += weightedAvgCurrentYield;

        const yieldAtCost = account.YieldatCost || 0;
        if (yieldAtCost !== 0) {
          totalCostBasis += account.CostBasis;
          totalShares += account.Quantity;
        }
      });

      positionYieldAtCost = (yrDivRate / (totalCostBasis / totalShares)) * 100;

      if (!isNaN(positionYieldAtCost)) {
        const positionPercentHoldings = positionMarketValue / totalMarketValue;
        totalYieldAtCost += positionYieldAtCost * positionPercentHoldings;
      }
    });
  }

  return {
    totalYieldAtCost,
    totalCurrentYield,
  };
};

export default getYieldTotals;
