export const loadNewNotifications = {
  namespace: 'notification',
  type: 'loadNewNotifications'
}

export const loadReadNotifications = (page = 1, pageSize = 10) => ({
  namespace: 'notification',
  type: 'loadReadNotifications',
  payload: { page, pageSize }
})

export const setNewNotifications = newNotifications => ({
  namespace: 'notification',
  type: 'setNewNotifications',
  payload: { newNotifications }
})

export const setReadNotifications = readNotifications => ({
  namespace: 'notification',
  type: 'setReadNotifications',
  payload: { readNotifications }
})

export const markAllAsRead = {
  namespace: 'notification',
  type: 'markAllAsRead'
}

export const markAsRead = id => ({
  namespace: 'notification',
  type: 'markAsRead',
  payload: { id }
})