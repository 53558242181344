import { setSelectedAccounts } from "./../../../actions/manage";
import {
  loadAccounts,
  loadGroups,
  loadView,
  setPositions,
} from "./../../../actions/account";
import { loadConfig } from "./../../../actions/user";

const handler = async (action, api, dispatch) => {
  dispatch(setSelectedAccounts(null));

  const { id, onComplete } = action.payload;
  const response = await api.account.deleteAccount({ id });

  dispatch(setPositions(null));
  dispatch(loadConfig);
  dispatch(loadAccounts);
  dispatch(loadGroups);
  dispatch(loadView);

  onComplete && onComplete(response);
};

export { handler };
