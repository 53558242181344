import { setGroups } from  './../../../actions/account'

const handler = async (_action, api, dispatch) => {

  dispatch(setGroups([]))
  const groups = await api.account.getGroups()
  dispatch(setGroups(groups))

}

export { handler }