import { isEmpty, find } from 'lodash'

const handler = (action, { stockNotes }) => {

  const newStockNotes = [...stockNotes ]

  const currentNote = find(stockNotes, { ticker: action.payload.ticker })

  if (isEmpty(currentNote)) {
    newStockNotes.push({
      ticker: action.payload.ticker,
      notes: action.payload.note
    })
  } else {
    currentNote.notes = action.payload.note
  }

  return {
    stockNotes: newStockNotes
  }

}

export { handler }