export const addToPubSubSubscription = ({ type }) => ({
  namespace: "user",
  type: "addToPubSubSubscription",
  payload: { type },
});

export const exchangeToken = ({ token, type }) => ({
  namespace: "user",
  type: "exchangeToken",
  payload: { token, type },
});

export const loadConfig = {
  namespace: "user",
  type: "loadConfig",
};

export const loadSocket = {
  namespace: "user",
  type: "loadSocket",
};

export const setAuthedUser = (authedUser) => ({
  namespace: "user",
  type: "setAuthedUser",
  payload: { authedUser },
});

export const setBlockingMessage = (blockingMessage) => ({
  namespace: "user",
  type: "setBlockingMessage",
  payload: { blockingMessage },
});

export const setConfig = (config) => ({
  namespace: "user",
  type: "setConfig",
  payload: { config },
});

export const setIsAuthenticatedStatus = (isAuthenticated) => ({
  namespace: "user",
  type: "setIsAuthenticatedStatus",
  payload: { isAuthenticated },
});

export const setPubSubMessage = (pubSubMessage) => ({
  namespace: "user",
  type: "setPubSubMessage",
  payload: { pubSubMessage },
});

export const setNotificationMessage = (notificationMessage) => ({
  namespace: "user",
  type: "setNotificationMessage",
  payload: { notificationMessage },
});

export const setSocket = (socket) => ({
  namespace: "user",
  type: "setSocket",
  payload: { socket },
});

export const setIsSocketConnected = (isSocketConnected) => ({
  namespace: "user",
  type: "setIsSocketConnected",
  payload: { isSocketConnected },
});

export const setUserConfig = (
  key,
  value,
  callback = async () => {},
  forceCallback = false
) => ({
  namespace: "user",
  type: "setUserConfig",
  payload: { key, value, callback, forceCallback },
});

export const subscribeToPubSubHubs = ({ socketId, pubSubHubs }) => ({
  namespace: "user",
  type: "subscribeToPubSubHubs",
  payload: { socketId, pubSubHubs },
});

export const stampUser = {
  namespace: "user",
  type: "stampUser",
};

export const updateProfile = (profile) => ({
  namespace: "user",
  type: "updateProfile",
  payload: { profile },
});

export const validateAuthenticationStatus = {
  namespace: "user",
  type: "validateAuthenticationStatus",
};

export const setAsFreeSubscription = ({ onComplete }) => ({
  namespace: "user",
  type: "setAsFreeSubscription",
  payload: { onComplete },
});
