import { loadNewNotifications, loadReadNotifications } from '../../../actions/notification'

const handler = async (action, api, dispatch) => {

  const { id } = action.payload

  if (id !== 'none') {
    await api.notification.markAsRead({ id })
  }

  dispatch(loadNewNotifications)
  dispatch(loadReadNotifications())
  
}

export { handler }