import { values, isNull } from 'lodash'

const handler = (action, { currentMetrics }) => {

  const keyedMetrics = {}

  let storedMetrics = currentMetrics
  if (isNull(currentMetrics)) {
    storedMetrics = []
  }

  for (let currentMetric of storedMetrics) {
    keyedMetrics[currentMetric.ticker] = storedMetrics[currentMetric]
  }

  for (let currentPrice of action.payload.currentPrices) {
    if (currentPrice.ticker in keyedMetrics) {
      keyedMetrics[currentPrice.ticker] = {
        ...keyedMetrics[currentPrice.ticker],
        ...currentPrice
      }
    } else {
      keyedMetrics[currentPrice.ticker] = currentPrice
    }
  }

  return {
    currentMetrics: values(keyedMetrics)
  }

}

export { handler }
