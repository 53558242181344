import { find, head, isEmpty } from "lodash";
import { toPercent } from "./../../../../views/helpers";
//yield from position
const resolveYield = (position, currentMetrics) => {
  let divYield = null;

  if (
    head(position.accounts).divstatus === "Dividend" ||
    head(position.accounts).Type === "PREFERRED"
  ) {
    const account = head(position.accounts);
    divYield = account.EstYield;

    const cachedRecord = find(currentMetrics, {
      ticker: head(position.accounts).PricingSymbol,
    });
    if (!isEmpty(cachedRecord)) {
      const price = cachedRecord.price;
      divYield = (account.EstAnnualDiv / price) * 100;

      const LastDivAmount = account?.dividends?.LastDivAmount;
      const LastDivFreqNum = account?.dividends?.LastDivFreqNum;
      if (LastDivAmount && LastDivFreqNum) {
        divYield = ((LastDivAmount * LastDivFreqNum) / price) * 100;
      }
    }
  }
  return divYield;
};

export default resolveYield;
