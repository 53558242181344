import clearAuth from "./user/clearAuth";
import exchangeToken from "./user/exchangeToken";
import getAuthedUser from "./user/getAuthedUser";
import getUserConfig from "./user/getUserConfig";
import setUserConfig from "./user/setUserConfig";
import updateUserProfile from "./user/updateUserProfile";
import setAsFreeSubscription from "./user/setAsFreeSubscription";
import stampUser from "./user/stampUser";

const user = {
  clearAuth,
  exchangeToken,
  getAuthedUser,
  getUserConfig,
  setUserConfig,
  updateUserProfile,
  setAsFreeSubscription,
  stampUser,
};

export default user;
