import { find, isEmpty } from 'lodash'

const resolvePositionNote = (account, positionNotes) => {

  let note = null

  const userPositionId = account.userpositionid
  const found = find(positionNotes, { userPositionId })
  if (!isEmpty(found)) {
    note = found
  }

  return note

}

export default resolvePositionNote