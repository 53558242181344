import { processData } from './../../../actions/manage'

const handler = async (action, api, dispatch, getState) => {

  dispatch(processData(getState()))

  const { payload: { ticker, note } } = action
  await api.notes.createStockNote({ ticker, notes: note })

}

export { handler }
