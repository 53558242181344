import { setTickerNotes } from './../../../actions/notes'

const handler = async (action, api, dispatch) => {

  dispatch(setTickerNotes(null))
  const tickerNotes = await api.notes.getTickerNotes({ ticker: action.payload.ticker })
  dispatch(setTickerNotes(tickerNotes))

}

export { handler }
