import { invoke } from "./../lib/client";

const createScreener = async ({
  name,
  description,
  group,
  filters,
  isGlobal,
  isFavorite,
}) =>
  await invoke("createScreener", {
    name,
    description,
    group,
    filters,
    isGlobal,
    isFavorite,
  });

export default createScreener;
