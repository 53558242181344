import { setIncomeProjection } from './../../../actions/account'

const handler = async (action, api, dispatch, getState) => {

  const state = getState()
  const reinvestPercent = state.manage.reinvestPercent

  dispatch(setIncomeProjection(null))
  const positions = await api.account.getIncomeProjection({
    reinvestPercent,
    additionalContribution: action.payload.addContribute
  })
  dispatch(setIncomeProjection(positions))
  
}

export { handler }