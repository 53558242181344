
const handler = async (action, api) => {

  const { tickers, onComplete } = action.payload

  const response = await api.security.getSecurities({ tickers })

  onComplete && onComplete(response)
  
}

export { handler }