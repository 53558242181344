import { updateAlertList } from  './../../../actions/alerts'

const handler = async (action, api, dispatch) => {

  const { id, alertConfig, onComplete } = action.payload

  const response = await api.alerts.updateAlert({ id, alertConfig })

  dispatch(updateAlertList(response))
  onComplete && onComplete(response)

}

export { handler }