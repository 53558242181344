import {
  setIsAuthenticatedStatus,
  setAuthedUser
} from  './../../../actions/user'

const handler = async (_action, api, dispatch) => {

  try {
    const authedUser = await api.user.getAuthedUser()
    dispatch(setAuthedUser(authedUser))
    dispatch(setIsAuthenticatedStatus(true))
  } catch (err) {
    await api.user.clearAuth()
    dispatch(setAuthedUser(null))
    dispatch(setIsAuthenticatedStatus(false))
  }

}

export { handler }