import { setDividendReliability } from './../../../actions/security'

const handler = async (action, api, dispatch) => {

  const { ticker } = action.payload

  const dividendReliability = await api.security.getDividendReliability({ ticker })
  dispatch(setDividendReliability(dividendReliability))
  
}

export { handler }