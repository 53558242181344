import { setAuthedUser } from  './../../../actions/user'

const handler = async (action, api, dispatch) => {

  const { payload: { profile } } = action
  await api.user.updateUserProfile(profile)

  const authedUser = await api.user.getAuthedUser()
  dispatch(setAuthedUser(authedUser))

}

export { handler }
