export const createGroup = ({ name }) => ({
  namespace: "account",
  type: "createGroup",
  payload: { name },
});

export const deleteAccount = ({ id, onComplete }) => ({
  namespace: "account",
  type: "deleteAccount",
  payload: { id, onComplete },
});

export const deleteGroup = (id) => ({
  namespace: "account",
  type: "deleteGroup",
  payload: { id },
});

export const fetchPortfolio = {
  namespace: "account",
  type: "fetchPortfolio",
};

export const loadAccounts = {
  namespace: "account",
  type: "loadAccounts",
};

export const loadBaseProjectionData = {
  namespace: "account",
  type: "loadBaseProjectionData",
};

export const loadFullTransactionHistory = ({ userAccountId, page }) => ({
  namespace: "account",
  type: "loadFullTransactionHistory",
  payload: { userAccountId, page },
});

export const loadGroups = {
  namespace: "account",
  type: "loadGroups",
};

export const loadView = {
  namespace: "account",
  type: "loadView",
};

export const loadMonthlyDividend = {
  namespace: "account",
  type: "loadMonthlyDividend",
};

export const loadIncomeProjection = {
  namespace: "account",
  type: "loadIncomeProjection",
};

export const loadPendingLinks = {
  namespace: "account",
  type: "loadPendingLinks",
};

export const loadTransactions = {
  namespace: "account",
  type: "loadTransactions",
};

export const resetPendingLinks = {
  namespace: "account",
  type: "resetPendingLinks",
};

export const setAccounts = (accounts) => ({
  namespace: "account",
  type: "setAccounts",
  payload: { accounts },
});

export const setPlanAccounts = (planAccounts) => ({
  namespace: "account",
  type: "setPlanAccounts",
  payload: { planAccounts },
});

export const setPortfolioAccounts = (portfolioAccounts) => ({
  namespace: "account",
  type: "setPortfolioAccounts",
  payload: { portfolioAccounts },
});

export const setBaseProjectionData = (baseProjectionData) => ({
  namespace: "account",
  type: "setBaseProjectionData",
  payload: { baseProjectionData },
});

export const setDividendHistory = (dividendHistory) => ({
  namespace: "account",
  type: "setDividendHistory",
  payload: { dividendHistory },
});

export const setIncomeProjection = (incomeProjection) => ({
  namespace: "account",
  type: "setIncomeProjection",
  payload: { incomeProjection },
});

export const setInstitutionAlias = ({
  institutionId,
  institutionAlias,
  onComplete,
}) => ({
  namespace: "account",
  type: "setInstitutionAlias",
  payload: { institutionId, institutionAlias, onComplete },
});

export const setIsFetchingAccounts = (isFetchingAccounts) => ({
  namespace: "account",
  type: "setIsFetchingAccounts",
  payload: { isFetchingAccounts },
});

export const setTransactions = (transactions) => ({
  namespace: "account",
  type: "setTransactions",
  payload: { transactions },
});

export const setFullTransactionHistory = (fullTransactionHistory) => ({
  namespace: "account",
  type: "setFullTransactionHistory",
  payload: { fullTransactionHistory },
});

export const setGroups = (groups) => ({
  namespace: "account",
  type: "setGroups",
  payload: { groups },
});

export const setPendingLinks = (pendingLinks) => ({
  namespace: "account",
  type: "setPendingLinks",
  payload: { pendingLinks },
});

export const setPositions = (positions) => ({
  namespace: "account",
  type: "setPositions",
  payload: { positions },
});

export const setYodleeRequestsForDisplay = (requestIds) => ({
  namespace: "account",
  type: "setYodleeRequestsForDisplay",
  payload: { requestIds },
});

export const setYodleeRequestsNotForDisplay = (requestIds) => ({
  namespace: "account",
  type: "setYodleeRequestsNotForDisplay",
  payload: { requestIds },
});

export const updateAccount = ({ id, alias, groupId }) => ({
  namespace: "account",
  type: "updateAccount",
  payload: { id, alias, groupId },
});

export const updateGroup = ({ id, name }) => ({
  namespace: "account",
  type: "updateGroup",
  payload: { id, name },
});

export const updatePendingLink = ({ id, key, value }) => ({
  namespace: "account",
  type: "updatePendingLink",
  payload: { id, key, value },
});

export const updatePendingLinkByProviderAccountId = ({
  providerAccountId,
  key,
  value,
}) => ({
  namespace: "account",
  type: "updatePendingLinkByProviderAccountId",
  payload: { providerAccountId, key, value },
});

export const updatePositions = (state) => ({
  namespace: "account",
  type: "updatePositions",
  payload: { state },
});
