
const handler = async (action, api) => {

  const { institutionId, institutionAlias, onComplete } = action.payload
  const response = await api.account.setProviderAccountAlias({ providerAccountId: institutionId, providerAccountAlias: institutionAlias })

  onComplete && onComplete(response)

}

export { handler }