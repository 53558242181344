import { find, head, isEmpty } from "lodash";

const resolvePrice = (position, currentMetrics) => {
  let price = head(position.accounts).PosPrice;

  const cachedRecord = find(currentMetrics, {
    ticker: head(position.accounts).PricingSymbol,
  });
  if (!isEmpty(cachedRecord)) {
    price = cachedRecord.price;
    if (isNaN(price)) {
      price = head(position.accounts).PosPrice;
    }
  }

  return price;
};

export default resolvePrice;
