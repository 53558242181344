import { isEmpty, isNull } from 'lodash'

const getUserConfig = (state, key) => {

  const config = state.user.config

  if (
    !isNull(config) &&
    !isEmpty(config) &&
    !isEmpty(config.user_config) &&
    key in config.user_config
  )
  {
    return config.user_config[key]
  }

  return null

}

export default getUserConfig
