import moment from "moment"

const handler = (action, { tickerNotes, allNotes }) => {

  const newTickerNotes = [...tickerNotes ]
  const newAllNotes = [ ...allNotes ]
  const now = moment().format('YYYY-MM-DD HH:mm:ss')

  const newNote = {
    UserAccountIDs: action.payload.userAccountIds,
    UserNoteId: now,
    createdAt: now,
    notes: action.payload.notes,
    ticker: action.payload.ticker,
    updatedAt: now,
    userId: null
  }

  newTickerNotes.unshift(newNote)
  newAllNotes.push(newNote)

  return {
    tickerNotes: newTickerNotes,
    allNotes: newAllNotes
  }

}

export { handler }